.auth-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-wrapper {
        position: relative;
        width: -webkit-fill-available;

        label {
            display: flex;
            align-items: start;
            color: #727972;
        }

        mat-form-field {
            width: -webkit-fill-available;

        }

        span {
            position: absolute;
            right: 0.5rem;
            color: #707fff;
            font-size: 1rem;
            font-weight: 400;
        }
    }
    img{
        margin: 2.5rem;
    }

    h4{
        font-weight: 500;
    }

    p {
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        color: #727972;
        span{
            color: #707fff;
        }
    }

    button {
        margin-block: 2.5rem;
        padding-inline: 5rem;
        padding-block: 1.5rem;
    }
}