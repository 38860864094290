
// .mat-menu-panel.mat-menu-panel{
// 	min-width: 12ch;
//     max-width: 30ch;
// 	min-height: 0;

// 	.mat-menu-content{
// 		.mat-menu-item{
// 			line-height: 2.5em;
// 			height: 2.5em;
// 			padding: 0 var(--spacing-large);
// 		}

// 		&:not(:empty){
// 			padding-top: var(--spacing-small);
// 			padding-bottom: var(--spacing-small);
// 		}
	
// 	}
// }

.mat-mdc-menu-panel{
	.mat-mdc-menu-content{
		padding: var(--spacing-small) 0;

		.mat-mdc-menu-item{
			min-height: 0;
			padding: var(--spacing-small) var(--spacing-medium);
		}
	}
}
