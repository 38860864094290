.mat-button-toggles-primary {
    margin: 10px;

    .mat-button-toggle-checked {
        background-color: #5d7af6;
        color: white;
    }

    .mat-button-toggle {
        margin: -1px;
        width: 40px;
        height: 35px;
    }
}

.mat-button-toggles-secondary {
    margin: 10px;
    background-color: white;
    color: #5d7af6;
    font-size: 10px;
    width: 120px;

    .mat-button-toggle-checked {
        color: white;
        font-weight: bold;
        background-color: #5d7af6;
        width: auto;
    }

    .mat-button-toggle {
        margin: -1px;
        width: 120px;
        height: 100%;
    }
}



