report, report-v2 {
	display: block;
	width: 100%;
	height: 100%;
}

.page-list{
	report-page{
		//!!!Everything in side pages is measured in 'mm', 'in', 'pt', or em!!!
		//cause its not "interface" its simulated print

		background-color: white;
		color: black;
		font-size: 8.5pt;
		line-height: 1.3em;
		font-family: "Helvetica";//Courier|Helvetica|Symbol|Times-Roman|ZapfDingbats

		padding: 7mm;
		height: 11in;
		width: 8.5in;
		min-width: 8.5in;
		max-width: 8.5in;
		box-sizing: border-box;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;

		transform-origin: top left;

		>.content-window{
			overflow: hidden;
			position: relative;
			flex-grow: 1;

			>.content{
				position: relative;
				min-height: 100%;
				height: 100%;
				max-height: 100%;
				overflow: hidden;
			}

			>.markup-annotations{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
				z-index: 1;
			}

			>.annotate{
				background-color: #00000020;
			}
		}

		report-header, .report-header{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 2mm;

			&.has-sub-title{
				margin-bottom: calc(2mm + 1.5em);
			}

			.title{
				font-size: 2em;
				line-height: 1em;

				*{
					vertical-align: baseline;
					vertical-align: top;
					vertical-align: bottom;
					vertical-align: text-top;
					vertical-align: text-bottom;
					vertical-align: middle;
				}

				mat-icon{
					margin-left: -0.1em;
					font-size: inherit !important;
					height: 1em;
					width: 1em;
					color: var(--primary-color);
				}

				.report-name{
					text-transform: uppercase;
					font-size: 1em;
					font-weight: bold;
					position: relative;
				}

				.project-name{
					font-size: 0.6em;
					line-height: 1em;
				}
			}

			.company{
				font-size: 2em;
				line-height: 1em;

				>*{
					vertical-align: middle;
				}

				img{
					height: 1em;
				}
			}

			.sub-title{
				position: absolute;
				left: 2em;
				top: calc(2em + 1mm);
				>span{
					font-size: 1.5em;
				}
			}
		}

		.strip {
			height: 10px;

			&.bar-1 {
				background-color: #5636ff;
			}

			&.bar-2 {
				background-color:#5355ff;
			}
			
			&.bar-3 {
				background-color: #4f92ff;
			}
		}
		

		report-page-footer{
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			position: absolute;
			bottom:0px;
			line-height: 1em;
			margin-top: 1em;

			>.left{
				>*{
					vertical-align: middle;
				}

				mat-icon{
					margin-left: -0.1em;
					margin-right: -0.1em;
					font-size: inherit !important;
					height: 1em;
					width: 1em;
					color: var(--primary-color);
				}

				img{
					height: 1em;
				}
			}

			>.page-number{
				font-weight: bold;
				text-align: right;
			}
		}

		.graph{
			position: relative;
		}

		.graph::before{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: transparent;
		}

		&.summary{
			.content{
				font-size: 14pt;
				line-height: 1.3em;
				display: flex;
				flex-direction: column;
				gap: 1em;

				.box{
					padding: 0.66em 1em;
					margin-bottom: 0;


					>table.data{
						margin-top: -0.3em;
						margin-left: -1em;
						margin-right: -1em;
						width: calc(100% + 2em);

						th, td{
							padding: 0.3em  0.3em 0.15em;
							border-color: #00000055;
						}

						th:first-child, td:first-child{
							border-left: none;
							padding-left: 1em;
						}

						th:last-child, td:last-child{
							border-right: none;
							padding-right: 1em;
						}

						tr:first-child{
							th, td{
								border-top: none;
								background-color: transparent;
							}
						}

						tr:last-child{
							th, td{
								border-bottom: none;
							}
						}
					}
				}

				report-header{
					font-size: 0.5em;

					.sub-title>span{
						font-size: 2em;
					}
				}

				report-page-footer{
					font-size: 0.5em;
				}

				>.one-column{
					width: fit-content;
					min-width: 70%;
					max-width: 100%;
					padding: 0 1em;
					margin-left: auto;
					margin-right: auto;
					display: flex;
					flex-direction: column;
					gap: 1em;
				}

				>.two-column{
					padding: 0 1em;
					width: fit-content;
					min-width: 70%;
					max-width: 100%;
					margin-left: auto;
					margin-right: auto;
					display: grid;
					grid-template: '. .';
					gap: 1em;
				}
			}
		}
	}

	report-pages{
		display: contents;

		report-page{
			>.content-window{
				flex-grow: 0;

				>.content{
					height: auto;
					max-height: none;
				}
			}

		}
	}
}

@media screen{
	report{
		report-page-container{
			width: 0;
		}
	}
	report-v2{
		report-page-container{
			height: 0;
			width: 100%;
		}
	}

	report, report-v2{
		display: flex;
		flex-direction: row;

		position: absolute;

		>.controls{
			position: relative;
			width: 72.76mm;
			padding: var(--spacing-medium);
			overflow-x: hidden;
			overflow-y: auto;
		}

		report-page-container{
			display: block;
			flex: 1 1 0;
			position: relative;

			>.page-scroller{
				background-color: grey;
				height: 100%;
				max-height: 100%;
				box-sizing: border-box;
				overflow-x: scroll;
				overflow-y: auto;
				position: relative;//this is needed for htmlToPdf to handle the scroll properly
				padding: var(--spacing-large);

				>.page-scroller-inner{
					height: 100%;
					max-height: 100%;
					width: fit-content;
				}
			}

			.page-scroller::-webkit-scrollbar-button:single-button {
				display: none;
			}
			  

			>.scroll-buttons {
				position: absolute;
				bottom: calc(1.6rem + var(--spacing-medium));
				left: var(--spacing-medium);
				width: fit-content;

				display: flex;
				flex-direction: row;
				align-items: flex-start;
				pointer-events: none;

				>*{
					box-shadow: rgba(0, 0, 0, 0.533) 0px 0px 4px;
					pointer-events: all;
				}
			
			}
		}

		.icon-box {
			margin: 0rem 1rem 1rem 0rem;
			display: flex;
			width: 5rem;
			height: 5rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			border-radius: 0.5rem;
			border: 1px solid #e3e7f8;
			box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(227, 231, 248, 0.5);
			cursor: pointer;
		
			&.active{
				border: 2px solid #5d7af6;
			}
		
			span {
				font-size: 0.5rem;
			}
		}
		
		.actions{
			display: flex;
			place-content: flex-end;
			padding: 0;
			margin-top: var(--spacing-large);
			margin-bottom: 0;
			min-height: 0;
			position: absolute;
			bottom: var(--spacing-small);
			right:var(--spacing-small);
		
			button{
				border-radius: 0.5rem;
				padding: 0.5rem;
				margin: 0.5rem;
			}
		
			.confirm{
				background-color: #E3E7F8;
				color: #5D7AF6;
			}
		}
	}
}

.page-list{
	width: fit-content;
	position: relative;//this is needed for htmlToPdf to handle the scroll properly
	height: auto;
	display: flex;
	flex-direction: row;
	gap: var(--spacing-large);
	align-items: center;

	background-color: grey;

	&.zoom-fit{
		height: 100%;
		max-height: 100%;
	}

	&.zoom-rows2{
		display: grid;
		grid-template-areas: '. .';
		grid-auto-flow: column;
		grid-template-rows: repeat(2,auto);
		justify-items: center;
	}
}
.cdk-virtual-scroll-content-wrapper {
    gap: var(--spacing-large);
    display: flex;
}

@media print{
	.no-pdf{
		display: none !important;
	}

	iframe#launcher{
		display:none;
	}

	.report{
		.controls{
			display: none;
		}

		.page-list{
			transform: none !important;
		}

		.header{
			display: none;
		}

		>.controls{
			display: none;
		}

		.pages-and-controls{
			>.page-list{
				background-color: transparent;
				padding: 0;
			}
		}

		.pages, .page{
			background-color: transparent;
			padding: none;
		}
	}
}

.report{
	--divider-color: var(--primary-text-color);

	report-page{
		.font-large.font-large.font-large{
			font-size: 1.2em;
			line-height: 1.3em;
		}

		.font-medium.font-medium.font-medium{
			font-size: 1.0em;
			line-height: 1.3em;
		}

		.font-small.font-small.font-small{
			font-size: 0.8em;
			line-height: 1.3em;
		}

		.font-smaller.font-smaller.font-smaller{
			font-size: 0.4em;
			line-height: 1.3em;
		}

		.avoid-page-break{
			break-inside: avoid;
		}

		h1{
			font-weight: bold;
			font-size: 1.2em;
			padding: 0;
			margin: 0;
		}

		h2{
			font-weight: bold;
			font-size: 1.1em;
			padding: 0;
			margin: 0;
		}

		h3{
			font-weight: bold;
			padding: 0;
			margin: 0;
		}

		.row{
			margin-bottom: 2mm;
		}

		.box{
			background-color: white;
			padding: 2mm;
			margin-bottom: 2mm;
			border-radius: 1mm;

			.title{
				white-space: nowrap;
				text-transform: uppercase;
				padding: 0;
				margin-bottom: 0.3em;
				border-bottom: 0.1rem solid #00000055;
			}

			table.label-value{
				width: 100%;

				td:first-child{
					white-space: nowrap;
					width: 0.1rem;
				}

			}

			>table.label-value:not(:first-child){
				margin-top: 0.3em;
			}


			.flex-row{
				gap: 2mm;
			}
		}

		.label-above.label-above.label-above{
			margin-bottom: 1mm;
			line-height: 1em;
		}

		.color{
			border: 0.1rem solid gray;
			box-shadow: none;
		}

		span.placeholder{
			opacity: 0.66;

			&::before{
				content: '<';
			}
			&::after{
				content: '>';
			}
		}

		table.data{
			border-collapse: collapse;
			border-width: 0;

			tr{
				break-inside: avoid;
			}

			thead{
				break-inside: avoid;
			}

			th, td{
				padding: 1mm;
				border: 0.26mm solid gray;
			}

			td.left{
				text-align: left;
			}

			th{
				text-align: center;
				background-color: #eee;
				border-top-width: 0.26mm;
				font-weight: bold;
			}

			tr:nth-child(6n) td{
				border-top: 0.26mm solid black;
			}
		}

		table.label-value{
			border-collapse: collapse;

			td{
				vertical-align: baseline;
				text-align: left;
				padding: 0;
				padding-top: 0.3em;
				box-sizing: border-box;

				>*{
					vertical-align: baseline;
				}

				div.label-value-unaligned{
					display: flex;
					flex-direction: row;

					>*:first-child{
						text-transform: capitalize;
						padding-right: 1mm;
					}

					>*:last-child{
						padding-left: 1mm;
						flex-grow: 1;
						border-bottom: solid 0.1rem #00000055;
					}
				}
			}

			td:first-child:not(:only-child){
				text-transform: capitalize;
				padding-left: 0;
				padding-right: 1mm;
			}

			td:last-child:not(:only-child){
				padding-left: 1mm;
				padding-right: 1mm;
				text-align: left;
				vertical-align: bottom;
				border-bottom: solid 0.1rem #00000055;
			}

			tr:first-child{
				th, td{
					padding-top: 0;
				}
			}

		}

		table.label-value-data{
			border-collapse: collapse;
			border-width: 0;

			tr{
				break-inside: avoid;
			}

			td{
				padding: 0.5em;
				border: 0.26mm solid gray;
			}

			tr:nth-child(6n) td{
				border-top:0.26mm solid black;
			}

			td:first-child{
				padding-right: 0.75em;
			}

			td:not(:first-child){
				padding-left: calc(0.25em + 0.2rem);
			}
		}

		table.decks{
			border-collapse: collapse;
			background-color: white;

			td, th{
				padding: 0.3em 1mm 0;
				text-align: left;
				border-bottom: solid 0.1rem #00000055;
			}

			th{
				background-color: #F8F8F8;
			}

			td:first-child, th:first-child{
				padding-left: 0;
			}

			td:last-child, th:last-child{
				padding-right: 0;
			}

			tr:first-child{
				th, td{
					padding-top: 0;
				}
			}

			tr:last-child{
				th, td{
					border-bottom: none;;
				}
			}
		}

		div.label-value{
			> div{
				gap: 1em;
				margin-bottom: 0;
			}
		}
	}

	svg{
		display: block;
	}
}