
:host{
	padding: 1rem;
}

.mat-mdc-dialog-container{
	max-width: 90vw !important;
	max-height: 90vh !important;
	
	.title{	
		font-weight: 500;
		font-size: larger;
		padding: 1rem;
		background-color: var(--sidebar-background);
		color: var(--scroll-bar);
	}

	.mat-mdc-dialog-surface.mdc-dialog__surface{
		overflow: inherit;	
			::-webkit-scrollbar:horizontal {
			display: none;
		}

		>*{
			display: contents;
			padding: var(--spacing-medium);

			.mat-mdc-dialog-content{
				min-width:20rem;
				padding: 1rem;

				label{
					font-size: 0.75rem;
				}

				.content-container {
					.scroll {
						overflow-y: scroll;
						overflow-x: none;
					}
				}

				.flex-row{
					display: flex;
					flex-direction: row;
					flex-wrap: inherit;
				}

				.input-half{
					width:50%;
					padding-inline: 0.5rem;
				}

				mat-form-field{
					width: 100%;
				}
			}

			.mat-mdc-dialog-actions{
				display: flex;
				place-content: flex-end;
				padding: 0.5rem;
				margin-top: var(--spacing-medium);
				margin-bottom: 0;
				min-height: 0;

				button{
					border-radius: 0.5rem;
					padding: 0.5rem;
					margin: 0.5rem;
				}

				.confirm{
					background-color: #E3E7F8;
					color: #5D7AF6;
				}
			}
		}
		.report{
			.mat-mdc-dialog-content{
				max-width: 100rem !important;
			}
		}
	}
}
