.card-large {
	width: 8rem;
	height: 6.5rem;
	flex-shrink: 0;
	padding: 0.5rem;
	margin: 0.5rem;
	border-radius: 0.5rem;
	background: rgba(227, 231, 248, 0.15);
	box-shadow: none;

	.titlespan {
		margin-left: 0.5rem;
        font-size: 90%;
	}

	&.warn {
		background: rgba(248, 227, 227, 0.25);
	}

	&.primary {
		background: rgba(227, 231, 248, 0.25);
	}

}

@media (max-width: 1500px) {
	.card-large {
		width: 7rem;
		height: 5.5rem;
		margin-inline: auto;
		margin-block: 0.5rem;
		overflow: auto;

		.titlespan {
			font-size: 10px;
		}
	}
	.card-medium {
		height: 6.25rem !important;
	}
}

.card-medium {
	width: 6rem;
	height: 6.25rem;
	flex-shrink: 0;
	padding: 0.5rem;
	margin: 0.5rem;
	border-radius: 0.5rem;
	box-shadow: none;

	.titlespan {
		margin-left: 0.25rem;
		margin-bottom: 1rem;
		font-size: 90%;
	}

	mat-icon {
		height: 1rem;
		width: 1rem;
		position: relative;
		top: 0.25rem;
		left: -0.125rem;
	}

	&.warn {
		background: rgba(248, 227, 227, 0.25);
	}

	&.primary {
		background: rgba(227, 231, 248, 0.25);
	}

	&.long {
		width: 90%;
	}
}

.card-small {
	width: 4.5rem;
	height: 4.5rem;
	flex-shrink: 0;
	padding: 0.5rem;
	margin: 0.5rem;
	border-radius: 0.5rem;
	box-shadow: none;

	.titlespan {
		margin-left: 0.25rem;
		margin-bottom: 1rem;
		font-size: 90%;
	}

	mat-icon {
		height: 1rem;
		width: 1rem;
		position: relative;
		top: 0.25rem;
		left: -0.125rem;
	}

	&.warn {
		background: rgba(248, 227, 227, 0.25);
	}

	&.primary {
		background: rgba(227, 231, 248, 0.25);
	}

	&.long {
		width: 90%;
	}
}

.small-card-content {
	margin-top: 1rem;
	display: flex;
	justify-content: center;
	font-style: normal;
	font-weight: 500;

	&.warn {
		color: #e01e5a;
	}

	&.primary {
		color: var(--fontColor);
	}
}