
.data-table-caption, .data-table-settings{
	padding: 0.75em 1em;
	text-align: left;
	background-color: var(--panel-background);
	border-radius: var(--corner-radius);
	caption-side: top;

	border-radius: var(--corner-radius);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: var(--divider-color);
}

.data-table-caption{
	.title{
		font-size: 1.2em;
	}
}

.data-table-scroller{
	background: var(--panel-background);
	border-radius: var(--corner-radius);

	flex: 1 1 0;
	height: 0;
	overflow: auto;

	table.data{
		background: none;

		thead,tbody{
			>tr{
				>th{
					background: var(--panel-background-solid);
				}
			}	
		}
	}
}

.data-table-scroller::-webkit-scrollbar-track{
	background: var(--panel-background);
	background-color: #424242;
}

table.data{
	background: var(--panel-background);
	border-spacing: 0;
	border-radius: var(--corner-radius);
	//box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;

	>caption, >thead>tr>th, >tbody>tr>td{
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-bottom-color: var(--divider-color);
	}

	>caption{
		font-size: 1.2em;
		padding: var(--spacing-medium) var(--spacing-large);
		text-align: left;
		background: var(--panel-background);
		border-radius: var(--corner-radius);
		caption-side: top;
	}

	>thead{
		position: relative;
		top: 0;
		z-index: 1;		
	}

	thead,tbody{
		>tr{
			>th{
				font-weight: normal;
				color: var(--secondary-text-color);
				text-align: left;
			}

			>th,>td{
				padding: var(--spacing-small);

				&:first-child{
					padding-left: var(--spacing-large);
				}
				&:last-child{
					padding-right: var(--spacing-large);
				}

				// border-left-style: solid;
				// border-left-width: 1px;
				// border-left-color: color-mix(in srgb, var(--divider-color) 50%, transparent);
				// border-right-style: solid;
				// border-right-width: 1px;
				// border-right-color: color-mix(in srgb, var(--divider-color) 50%, transparent);
				// &:nth-child(odd){
				// 	background-color: color-mix(in srgb, currentColor 5%, transparent);
				// }
			}
		}
	}

	tbody>{
		mat-spinner{
			// padding: 0.75em 1.5em;
			font-size: 4em;
		}
	}

	tbody:empty{
		font-style: italic;
		display: block;
		padding: 0.75em 1.5em;
		
		&::before{
			content: 'None';
			opacity: 0.5;
		}
	}

	&.loading{
		tbody:empty::before{
			content: 'Loading';
		}
	}
}

table.labeled{
	border-spacing: 0;
	background: var(--panel-background);
	border-radius: var(--corner-radius);
	padding: 0 var(--spacing-small);

	th,td{
		padding: var(--spacing-small);
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-bottom-color: var(--divider-color);
	}

	tr:last-child>th,tr:last-child>td{
		border-bottom-style: none;
	}

	th{
		padding-left: 0;
		font-weight: normal;
		color: var(--secondary-text-color);
		text-align: left;
	}	

	td{
		padding-right: 0;
		color: var(--primary-text-color);
		text-align: right;
	}
}