.mat-icon.mat-icon{
    display: inline-block;
	font-size: 1.166666em;
    // height: 1em;
    // width: 1em;
    line-height: 1em;
    overflow: hidden;

    // svg{
    //     width: 1em;
    //     height: 1em;
    // }
}
