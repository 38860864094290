mat-select{
	.mat-mdc-select-trigger{
		gap: var(--spacing-small);

		.mat-mdc-select-arrow-wrapper{
			width: auto;
			height: 1.166666em;

		}
	}
}

.mat-mdc-select-panel{
	mat-option.mat-mdc-option{
	    padding: var(--spacing-small);
		min-height: 0;

		.mat-pseudo-checkbox-minimal{
			margin-left: 0;
		}
	}
}
