
mat-form-field.mat-mdc-form-field{
	--padding-horz: calc((0.5em + 0.5em)/2);
	--padding-vert: calc((0.5em + 0.5em)/2);
	--label-font-size: calc((0.66em + 0.66rem)/2);
	--mat-mdc-form-field-floating-label-scale: calc(var(--label-font-size)/1em);
	--label-transition: cubic-bezier(0.4, 0, 0.2, 1);

	&.mat-form-field-appearance-outline{
		border-top: solid transparent calc(var(--label-font-size)/2);

		>.mat-mdc-text-field-wrapper{
			padding-left: var(--padding-horz);
		    padding-right: var(--padding-horz);

			>.mat-mdc-form-field-flex{
				.mdc-notched-outline{
					.mdc-notched-outline__leading{
						width: var(--padding-horz);
					}

					.mdc-notched-outline__notch{
						max-width: 100%;
					}
				}

				.mat-mdc-floating-label{
					font-size: 1em;
					user-select: none;
					display: block;
					top: 50%;
					height: auto;
					left: 0;
					transform: translateY(-50%);
					transition: font-size 150ms var(--label-transition), height 150ms var(--label-transition), top 150ms var(--label-transition), left 150ms var(--label-transition), color 150ms var(--label-transition);

					&.mdc-floating-label--float-above{
						font-size: var(--label-font-size);
						height: 1em;
						top: 0%;
						left: 4px;
						transform: translateY(-50%);
					}
				}

				>.mat-mdc-form-field-infix{
					padding-top: var(--padding-vert);
					padding-bottom: var(--padding-vert);
					min-height: 0;
					width: auto;
					line-height: 1;

					input: {
						height: 1em;
						line-height: normal;
					}
					input:-internal-autofill-selected, input:-webkit-autofill{
						background-color: transparent !important;
						color: black !important;
						-webkit-text-fill-color: black;
					}

				}

				>.mat-mdc-form-field-icon-suffix{
					padding-left: var(--padding-horz);

					.mat-icon, svg{
						padding: 0;
					}

					.mdc-icon-button{
						margin: calc(var(--spacing-small)*-1);
					}
				}
			}
		}

		>.mat-mdc-form-field-subscript-wrapper{
			font-size: 60%;
			line-height: 1.125em;
			padding-top: 0.25em;
			padding-bottom: 0.5em;

			&.mat-mdc-form-field-bottom-align::before{
				display: none;
			}

			>.mat-mdc-form-field-hint-wrapper, >.mat-mdc-form-field-error-wrapper{
				position: relative;
			}
		}
	}

}

.mat-mdc-input-element{
	line-height: normal;
}

.mat-mdc-select-trigger{
	gap: 1ch;
}
