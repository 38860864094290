
.mat-mdc-paginator{
	border-radius: 4px;
	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: var(--divider-color);

	.mat-paginator-container{
		min-height: 0;
		padding: var(--spacing-small);
	}
	
	.mat-mdc-paginator-page-size{
		margin-right: var(--spacing-medium);
	}

	.mat-mdc-paginator-page-size-select{
		width: 8ch;
	}

	.mat-mdc-paginator-range-actions{
		color: var(--primary-text-color);

		.mat-mdc-paginator-range-label{
			margin: 0;
			color: var( --secondary-text-color);
		}

		button{
			margin-left: var(--spacing-medium);
			padding: 0;
			font-size: 150%;
		}
		
		button:last-child{
			margin-left: var(--spacing-small);
		}
	}
}
