@import './variables.scss';

.mat-simple-snackbar {
    font-size: 1.2em;
    border-radius: 0.5rem;
    position: relative !important;
    bottom: 3.25rem !important;
    right: 6.5rem !important;
}

.snackbar-error {
    background-color: $custom-warn;
}

.snackbar-success {
    background-color: $custom-primary;
}

.snackbar-info {
    background-color: $custom-black;
}

.mdc-snackbar__surface {
    background-color: var(--color-primary) !important;
}

.mdc-snackbar__label {
    color: white !important;
    font-size: 1.2em;
}