mat-calendar.mat-calendar{
	width: calc(var(--spacing-medium)*9 + 2ch*9);
	padding: 20px;
	border-radius: 28px;
  	font-weight: 400;

	.mat-calendar-header{
		padding-top: var(--spacing-small);
		padding-left: var(--spacing-small);
		padding-right: var(--spacing-small);

		.mat-calendar-controls{
			margin-top: 0;
			margin-bottom: 0;
			gap: var(--spacing-small);
			
			.mat-mdc-icon-button.mat-mdc-button-base {
				--mdc-icon-button-state-layer-size: 0.8em;
				// width: auto;
				// height: auto;
				padding: var(--spacing-small);
				
				&::after{
					margin: 0;
					top: 30%;
					right: 30%;
					bottom: 30%;
					left: 30%;
				}
			}
		}	
	}

	.mat-calendar-table-header{
		th{
			padding-bottom: var(--spacing-medium);
		}
	}

	.mat-calendar-content{
		padding: 0;
	}

	.mat-calendar-table{
		margin-top: 1em;
		font-size: 12px;
		font-weight: 400;
	}
	.mat-calendar-body-label {
		height: 0;
		line-height: 0;
		text-align: left;
		color: transparent;
		padding-left: 4.7142857143%;
		padding-right: 4.7142857143%;
	}
}
