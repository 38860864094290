.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1c1d1c);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #e01e5a);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #5d7af6;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #5d7af6;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1c1d1c;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1c1d1c;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1c1d1c;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1c1d1c;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e01e5a;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e01e5a;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #5d7af6;
  --mdc-linear-progress-track-color: rgba(93, 122, 246, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(93, 122, 246, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 122, 246, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(93, 122, 246, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(93, 122, 246, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 122, 246, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1c1d1c;
  --mdc-linear-progress-track-color: rgba(28, 29, 28, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(28, 29, 28, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(28, 29, 28, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(28, 29, 28, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(28, 29, 28, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(28, 29, 28, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #e01e5a;
  --mdc-linear-progress-track-color: rgba(224, 30, 90, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(224, 30, 90, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(224, 30, 90, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 30, 90, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 30, 90, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(224, 30, 90, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #5d7af6);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #5d7af6);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #5d7af6);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(93, 122, 246, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #e01e5a);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(93, 122, 246, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(28, 29, 28, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(224, 30, 90, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1c1d1c);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1c1d1c);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(28, 29, 28, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1c1d1c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 30, 90, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d7af6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(93, 122, 246, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(28, 29, 28, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(224, 30, 90, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(224, 30, 90, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d7af6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #5d7af6;
  --mdc-chip-elevated-disabled-container-color: #5d7af6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1c1d1c;
  --mdc-chip-elevated-disabled-container-color: #1c1d1c;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e01e5a;
  --mdc-chip-elevated-disabled-container-color: #e01e5a;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #5572f5;
  --mdc-switch-selected-handle-color: #5572f5;
  --mdc-switch-selected-hover-state-layer-color: #5572f5;
  --mdc-switch-selected-pressed-state-layer-color: #5572f5;
  --mdc-switch-selected-focus-handle-color: #304aef;
  --mdc-switch-selected-hover-handle-color: #304aef;
  --mdc-switch-selected-pressed-handle-color: #304aef;
  --mdc-switch-selected-focus-track-color: #8ea2f9;
  --mdc-switch-selected-hover-track-color: #8ea2f9;
  --mdc-switch-selected-pressed-track-color: #8ea2f9;
  --mdc-switch-selected-track-color: #8ea2f9;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #191a19;
  --mdc-switch-selected-handle-color: #191a19;
  --mdc-switch-selected-hover-state-layer-color: #191a19;
  --mdc-switch-selected-pressed-state-layer-color: #191a19;
  --mdc-switch-selected-focus-handle-color: #090a09;
  --mdc-switch-selected-hover-handle-color: #090a09;
  --mdc-switch-selected-pressed-handle-color: #090a09;
  --mdc-switch-selected-focus-track-color: #606160;
  --mdc-switch-selected-hover-track-color: #606160;
  --mdc-switch-selected-pressed-track-color: #606160;
  --mdc-switch-selected-track-color: #606160;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc1a52;
  --mdc-switch-selected-handle-color: #dc1a52;
  --mdc-switch-selected-hover-state-layer-color: #dc1a52;
  --mdc-switch-selected-pressed-state-layer-color: #dc1a52;
  --mdc-switch-selected-focus-handle-color: #cb0a2e;
  --mdc-switch-selected-hover-handle-color: #cb0a2e;
  --mdc-switch-selected-pressed-handle-color: #cb0a2e;
  --mdc-switch-selected-focus-track-color: #e9628c;
  --mdc-switch-selected-hover-track-color: #e9628c;
  --mdc-switch-selected-pressed-track-color: #e9628c;
  --mdc-switch-selected-track-color: #e9628c;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d7af6;
  --mdc-radio-selected-hover-icon-color: #5d7af6;
  --mdc-radio-selected-icon-color: #5d7af6;
  --mdc-radio-selected-pressed-icon-color: #5d7af6;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #5d7af6;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1c1d1c;
  --mdc-radio-selected-hover-icon-color: #1c1d1c;
  --mdc-radio-selected-icon-color: #1c1d1c;
  --mdc-radio-selected-pressed-icon-color: #1c1d1c;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #1c1d1c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e01e5a;
  --mdc-radio-selected-hover-icon-color: #e01e5a;
  --mdc-radio-selected-icon-color: #e01e5a;
  --mdc-radio-selected-pressed-icon-color: #e01e5a;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #e01e5a;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #5d7af6;
  --mdc-slider-focus-handle-color: #5d7af6;
  --mdc-slider-hover-handle-color: #5d7af6;
  --mdc-slider-active-track-color: #5d7af6;
  --mdc-slider-inactive-track-color: #5d7af6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5d7af6;
  --mat-mdc-slider-ripple-color: #5d7af6;
  --mat-mdc-slider-hover-ripple-color: rgba(93, 122, 246, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(93, 122, 246, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1c1d1c;
  --mdc-slider-focus-handle-color: #1c1d1c;
  --mdc-slider-hover-handle-color: #1c1d1c;
  --mdc-slider-active-track-color: #1c1d1c;
  --mdc-slider-inactive-track-color: #1c1d1c;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1c1d1c;
  --mat-mdc-slider-ripple-color: #1c1d1c;
  --mat-mdc-slider-hover-ripple-color: rgba(28, 29, 28, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(28, 29, 28, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #e01e5a;
  --mdc-slider-focus-handle-color: #e01e5a;
  --mdc-slider-hover-handle-color: #e01e5a;
  --mdc-slider-active-track-color: #e01e5a;
  --mdc-slider-inactive-track-color: #e01e5a;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e01e5a;
  --mat-mdc-slider-ripple-color: #e01e5a;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 30, 90, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 30, 90, 0.2);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d7af6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d7af6;
  --mdc-radio-selected-hover-icon-color: #5d7af6;
  --mdc-radio-selected-icon-color: #5d7af6;
  --mdc-radio-selected-pressed-icon-color: #5d7af6;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1c1d1c;
  --mdc-radio-selected-hover-icon-color: #1c1d1c;
  --mdc-radio-selected-icon-color: #1c1d1c;
  --mdc-radio-selected-pressed-icon-color: #1c1d1c;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e01e5a;
  --mdc-radio-selected-hover-icon-color: #e01e5a;
  --mdc-radio-selected-icon-color: #e01e5a;
  --mdc-radio-selected-pressed-icon-color: #e01e5a;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d7af6;
  --mdc-checkbox-selected-hover-icon-color: #5d7af6;
  --mdc-checkbox-selected-icon-color: #5d7af6;
  --mdc-checkbox-selected-pressed-icon-color: #5d7af6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-hover-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d7af6;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1c1d1c;
  --mdc-checkbox-selected-hover-icon-color: #1c1d1c;
  --mdc-checkbox-selected-icon-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-icon-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-hover-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-state-layer-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e01e5a;
  --mdc-checkbox-selected-hover-icon-color: #e01e5a;
  --mdc-checkbox-selected-icon-color: #e01e5a;
  --mdc-checkbox-selected-pressed-icon-color: #e01e5a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-hover-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-pressed-state-layer-color: #e01e5a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #5d7af6;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #5d7af6;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #5d7af6;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #5d7af6;
  --mat-tab-header-active-ripple-color: #5d7af6;
  --mat-tab-header-inactive-ripple-color: #5d7af6;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #5d7af6;
  --mat-tab-header-active-hover-label-text-color: #5d7af6;
  --mat-tab-header-active-focus-indicator-color: #5d7af6;
  --mat-tab-header-active-hover-indicator-color: #5d7af6;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1c1d1c;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #1c1d1c;
  --mat-tab-header-active-ripple-color: #1c1d1c;
  --mat-tab-header-inactive-ripple-color: #1c1d1c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1c1d1c;
  --mat-tab-header-active-hover-label-text-color: #1c1d1c;
  --mat-tab-header-active-focus-indicator-color: #1c1d1c;
  --mat-tab-header-active-hover-indicator-color: #1c1d1c;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #e01e5a;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e01e5a;
  --mat-tab-header-active-ripple-color: #e01e5a;
  --mat-tab-header-inactive-ripple-color: #e01e5a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e01e5a;
  --mat-tab-header-active-hover-label-text-color: #e01e5a;
  --mat-tab-header-active-focus-indicator-color: #e01e5a;
  --mat-tab-header-active-hover-indicator-color: #e01e5a;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #5d7af6;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1c1d1c;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #e01e5a;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1c1d1c;
  --mdc-checkbox-selected-hover-icon-color: #1c1d1c;
  --mdc-checkbox-selected-icon-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-icon-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-hover-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-state-layer-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d7af6;
  --mdc-checkbox-selected-hover-icon-color: #5d7af6;
  --mdc-checkbox-selected-icon-color: #5d7af6;
  --mdc-checkbox-selected-pressed-icon-color: #5d7af6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-hover-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d7af6;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e01e5a;
  --mdc-checkbox-selected-hover-icon-color: #e01e5a;
  --mdc-checkbox-selected-icon-color: #e01e5a;
  --mdc-checkbox-selected-pressed-icon-color: #e01e5a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-hover-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-pressed-state-layer-color: #e01e5a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #5d7af6;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1c1d1c;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e01e5a;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #5d7af6;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1c1d1c;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e01e5a;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #5d7af6;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1c1d1c;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e01e5a;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #5d7af6;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1c1d1c;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e01e5a;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #5d7af6;
  --mat-mdc-button-ripple-color: rgba(93, 122, 246, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1c1d1c;
  --mat-mdc-button-ripple-color: rgba(28, 29, 28, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e01e5a;
  --mat-mdc-button-ripple-color: rgba(224, 30, 90, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #5d7af6;
  --mat-mdc-button-persistent-ripple-color: #5d7af6;
  --mat-mdc-button-ripple-color: rgba(93, 122, 246, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1c1d1c;
  --mat-mdc-button-persistent-ripple-color: #1c1d1c;
  --mat-mdc-button-ripple-color: rgba(28, 29, 28, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e01e5a;
  --mat-mdc-button-persistent-ripple-color: #e01e5a;
  --mat-mdc-button-ripple-color: rgba(224, 30, 90, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #5d7af6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1c1d1c;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #e01e5a;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1c1d1c;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(93, 122, 246, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #5d7af6;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1c1d1c;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #e01e5a;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #5d7af6;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #1c1d1c;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #e01e5a;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(93, 122, 246, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 122, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 122, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #5d7af6;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 122, 246, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 122, 246, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(93, 122, 246, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(28, 29, 28, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(28, 29, 28, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(28, 29, 28, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1c1d1c;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(28, 29, 28, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(28, 29, 28, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(28, 29, 28, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(224, 30, 90, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 30, 90, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 30, 90, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e01e5a;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 30, 90, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 30, 90, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 30, 90, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #5d7af6;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #1c1d1c;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #e01e5a;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #5d7af6;
}
.mat-icon.mat-accent {
  color: #1c1d1c;
}
.mat-icon.mat-warn {
  color: #e01e5a;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #5d7af6;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1c1d1c;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e01e5a;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e01e5a;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e01e5a;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #5d7af6;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #1c1d1c;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #e01e5a;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.theme-dark {
  --fontColor: #707fff;
  --charts-background: transparent;
  --secondary-text-color: #ffffff;
  --action-button: #000000;
  --view-button: #000000;
  --sidebar-background: #5d7af6;
  --charts-font: #ffffff;
  --highcharts-labels:#ffffff;
  --scroll-bar:#000000;
  --dialog-background: #424242;
  --mat-toolbar:#303030;
  --popup-color: #ffffff;
  --panel-background: rgba(12, 12, 12, 0.5);
  --panel-background-solid: rgba(12, 12, 12, 0.5);
  --popup-background: #000000 !important;
  --svg-filter: brightness(0) saturate(100%) invert(94%) sepia(75%) saturate(26%) hue-rotate(259deg) brightness(113%) contrast(94%);
  --density-background: #000000;
}
.theme-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-dark .mat-mdc-option:focus.mdc-list-item, .theme-dark .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .theme-dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1c1d1c);
}
.theme-dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #5d7af6;
}
.theme-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #5d7af6;
}
.theme-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1c1d1c;
}
.theme-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1c1d1c;
}
.theme-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1c1d1c;
}
.theme-dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1c1d1c;
}
.theme-dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e01e5a;
}
.theme-dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e01e5a;
}
.theme-dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.theme-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.theme-dark .mat-app-background, .theme-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.theme-dark .mat-elevation-z0, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z1, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z2, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z3, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z4, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z5, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z6, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z7, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z8, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z9, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z10, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z11, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z12, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z13, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z14, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z15, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z16, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z17, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z18, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z19, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z20, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z21, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z22, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z23, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-elevation-z24, .theme-dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #5d7af6;
  --mdc-linear-progress-track-color: rgba(93, 122, 246, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.theme-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(93, 122, 246, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 122, 246, 0.25));
}
@media (forced-colors: active) {
  .theme-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(93, 122, 246, 0.25)'/%3E%3C/svg%3E");
  }
}
.theme-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(93, 122, 246, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 122, 246, 0.25));
}
.theme-dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1c1d1c;
  --mdc-linear-progress-track-color: rgba(28, 29, 28, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.theme-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(28, 29, 28, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(28, 29, 28, 0.25));
}
@media (forced-colors: active) {
  .theme-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(28, 29, 28, 0.25)'/%3E%3C/svg%3E");
  }
}
.theme-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(28, 29, 28, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(28, 29, 28, 0.25));
}
.theme-dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #e01e5a;
  --mdc-linear-progress-track-color: rgba(224, 30, 90, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.theme-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(224, 30, 90, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(224, 30, 90, 0.25));
}
@media (forced-colors: active) {
  .theme-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 30, 90, 0.25)'/%3E%3C/svg%3E");
  }
}
.theme-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 30, 90, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(224, 30, 90, 0.25));
}
.theme-dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #4a4a4a;
}
.theme-dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #5d7af6);
}
[dir=rtl] .theme-dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(93, 122, 246, 0.87);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .theme-dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #464646;
}
[dir=rtl] .theme-dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .theme-dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(93, 122, 246, 0.87);
}
.theme-dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(28, 29, 28, 0.87);
}
.theme-dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(224, 30, 90, 0.87);
}
.theme-dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1c1d1c);
}
.theme-dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1c1d1c);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(28, 29, 28, 0.87);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1c1d1c);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 30, 90, 0.87);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e01e5a);
}
.theme-dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-dark .mat-mdc-form-field-infix {
  min-height: 56px;
}
.theme-dark .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
.theme-dark .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-dark .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.theme-dark .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}
.theme-dark .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.theme-dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(93, 122, 246, 0.87);
}
.theme-dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(28, 29, 28, 0.87);
}
.theme-dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(224, 30, 90, 0.87);
}
.theme-dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(224, 30, 90, 0.87);
}
.theme-dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.theme-dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #5d7af6;
  --mdc-chip-elevated-disabled-container-color: #5d7af6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1c1d1c;
  --mdc-chip-elevated-disabled-container-color: #1c1d1c;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e01e5a;
  --mdc-chip-elevated-disabled-container-color: #e01e5a;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-dark .mat-mdc-chip-focus-overlay {
  background: white;
}
.theme-dark .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.theme-dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #8ea2f9;
  --mdc-switch-selected-handle-color: #8ea2f9;
  --mdc-switch-selected-hover-state-layer-color: #8ea2f9;
  --mdc-switch-selected-pressed-state-layer-color: #8ea2f9;
  --mdc-switch-selected-focus-handle-color: #aebdfb;
  --mdc-switch-selected-hover-handle-color: #aebdfb;
  --mdc-switch-selected-pressed-handle-color: #aebdfb;
  --mdc-switch-selected-focus-track-color: #5572f5;
  --mdc-switch-selected-hover-track-color: #5572f5;
  --mdc-switch-selected-pressed-track-color: #5572f5;
  --mdc-switch-selected-track-color: #5572f5;
}
.theme-dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #606160;
  --mdc-switch-selected-handle-color: #606160;
  --mdc-switch-selected-hover-state-layer-color: #606160;
  --mdc-switch-selected-pressed-state-layer-color: #606160;
  --mdc-switch-selected-focus-handle-color: #8e8e8e;
  --mdc-switch-selected-hover-handle-color: #8e8e8e;
  --mdc-switch-selected-pressed-handle-color: #8e8e8e;
  --mdc-switch-selected-focus-track-color: #191a19;
  --mdc-switch-selected-hover-track-color: #191a19;
  --mdc-switch-selected-pressed-track-color: #191a19;
  --mdc-switch-selected-track-color: #191a19;
}
.theme-dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e9628c;
  --mdc-switch-selected-handle-color: #e9628c;
  --mdc-switch-selected-hover-state-layer-color: #e9628c;
  --mdc-switch-selected-pressed-state-layer-color: #e9628c;
  --mdc-switch-selected-focus-handle-color: #f08fad;
  --mdc-switch-selected-hover-handle-color: #f08fad;
  --mdc-switch-selected-pressed-handle-color: #f08fad;
  --mdc-switch-selected-focus-track-color: #dc1a52;
  --mdc-switch-selected-hover-track-color: #dc1a52;
  --mdc-switch-selected-pressed-track-color: #dc1a52;
  --mdc-switch-selected-track-color: #dc1a52;
}
.theme-dark .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.theme-dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d7af6;
  --mdc-radio-selected-hover-icon-color: #5d7af6;
  --mdc-radio-selected-icon-color: #5d7af6;
  --mdc-radio-selected-pressed-icon-color: #5d7af6;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #5d7af6;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #1c1d1c;
  --mdc-radio-selected-hover-icon-color: #1c1d1c;
  --mdc-radio-selected-icon-color: #1c1d1c;
  --mdc-radio-selected-pressed-icon-color: #1c1d1c;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #1c1d1c;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #e01e5a;
  --mdc-radio-selected-hover-icon-color: #e01e5a;
  --mdc-radio-selected-icon-color: #e01e5a;
  --mdc-radio-selected-pressed-icon-color: #e01e5a;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #e01e5a;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}
.theme-dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #5d7af6;
  --mdc-slider-focus-handle-color: #5d7af6;
  --mdc-slider-hover-handle-color: #5d7af6;
  --mdc-slider-active-track-color: #5d7af6;
  --mdc-slider-inactive-track-color: #5d7af6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5d7af6;
  --mat-mdc-slider-ripple-color: #5d7af6;
  --mat-mdc-slider-hover-ripple-color: rgba(93, 122, 246, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(93, 122, 246, 0.2);
}
.theme-dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1c1d1c;
  --mdc-slider-focus-handle-color: #1c1d1c;
  --mdc-slider-hover-handle-color: #1c1d1c;
  --mdc-slider-active-track-color: #1c1d1c;
  --mdc-slider-inactive-track-color: #1c1d1c;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1c1d1c;
  --mat-mdc-slider-ripple-color: #1c1d1c;
  --mat-mdc-slider-hover-ripple-color: rgba(28, 29, 28, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(28, 29, 28, 0.2);
}
.theme-dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #e01e5a;
  --mdc-slider-focus-handle-color: #e01e5a;
  --mdc-slider-hover-handle-color: #e01e5a;
  --mdc-slider-active-track-color: #e01e5a;
  --mdc-slider-inactive-track-color: #e01e5a;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e01e5a;
  --mat-mdc-slider-ripple-color: #e01e5a;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 30, 90, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 30, 90, 0.2);
}
.theme-dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-dark .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d7af6);
}
.theme-dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-dark .mat-mdc-menu-item[disabled],
.theme-dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.theme-dark .mdc-list-item__start,
.theme-dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d7af6;
  --mdc-radio-selected-hover-icon-color: #5d7af6;
  --mdc-radio-selected-icon-color: #5d7af6;
  --mdc-radio-selected-pressed-icon-color: #5d7af6;
}
.theme-dark .mat-accent .mdc-list-item__start,
.theme-dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #1c1d1c;
  --mdc-radio-selected-hover-icon-color: #1c1d1c;
  --mdc-radio-selected-icon-color: #1c1d1c;
  --mdc-radio-selected-pressed-icon-color: #1c1d1c;
}
.theme-dark .mat-warn .mdc-list-item__start,
.theme-dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #e01e5a;
  --mdc-radio-selected-hover-icon-color: #e01e5a;
  --mdc-radio-selected-icon-color: #e01e5a;
  --mdc-radio-selected-pressed-icon-color: #e01e5a;
}
.theme-dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d7af6;
  --mdc-checkbox-selected-hover-icon-color: #5d7af6;
  --mdc-checkbox-selected-icon-color: #5d7af6;
  --mdc-checkbox-selected-pressed-icon-color: #5d7af6;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-hover-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d7af6;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1c1d1c;
  --mdc-checkbox-selected-hover-icon-color: #1c1d1c;
  --mdc-checkbox-selected-icon-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-icon-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-hover-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-state-layer-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e01e5a;
  --mdc-checkbox-selected-hover-icon-color: #e01e5a;
  --mdc-checkbox-selected-icon-color: #e01e5a;
  --mdc-checkbox-selected-pressed-icon-color: #e01e5a;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-hover-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-pressed-state-layer-color: #e01e5a;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #5d7af6;
}
.theme-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #5d7af6;
}
.theme-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-dark .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
.theme-dark .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .theme-dark .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .theme-dark .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.theme-dark .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .theme-dark .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .theme-dark .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}
.theme-dark .mat-mdc-paginator {
  background: #424242;
  color: rgba(255, 255, 255, 0.87);
}
.theme-dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-dark .mat-mdc-paginator-decrement,
.theme-dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-dark .mat-mdc-paginator-first,
.theme-dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.theme-dark .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.theme-dark .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-dark .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-dark .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-dark .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-dark .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.theme-dark .mat-mdc-paginator-container {
  min-height: 56px;
}
.theme-dark .mat-mdc-tab-group, .theme-dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #5d7af6;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #5d7af6;
  --mat-tab-header-active-ripple-color: #5d7af6;
  --mat-tab-header-inactive-ripple-color: #5d7af6;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #5d7af6;
  --mat-tab-header-active-hover-label-text-color: #5d7af6;
  --mat-tab-header-active-focus-indicator-color: #5d7af6;
  --mat-tab-header-active-hover-indicator-color: #5d7af6;
}
.theme-dark .mat-mdc-tab-group.mat-accent, .theme-dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1c1d1c;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1c1d1c;
  --mat-tab-header-active-ripple-color: #1c1d1c;
  --mat-tab-header-inactive-ripple-color: #1c1d1c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1c1d1c;
  --mat-tab-header-active-hover-label-text-color: #1c1d1c;
  --mat-tab-header-active-focus-indicator-color: #1c1d1c;
  --mat-tab-header-active-hover-indicator-color: #1c1d1c;
}
.theme-dark .mat-mdc-tab-group.mat-warn, .theme-dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #e01e5a;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #e01e5a;
  --mat-tab-header-active-ripple-color: #e01e5a;
  --mat-tab-header-inactive-ripple-color: #e01e5a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e01e5a;
  --mat-tab-header-active-hover-label-text-color: #e01e5a;
  --mat-tab-header-active-focus-indicator-color: #e01e5a;
  --mat-tab-header-active-hover-indicator-color: #e01e5a;
}
.theme-dark .mat-mdc-tab-group.mat-background-primary, .theme-dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #5d7af6;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-dark .mat-mdc-tab-group.mat-background-accent, .theme-dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1c1d1c;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-dark .mat-mdc-tab-group.mat-background-warn, .theme-dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #e01e5a;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-dark .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.theme-dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1c1d1c;
  --mdc-checkbox-selected-hover-icon-color: #1c1d1c;
  --mdc-checkbox-selected-icon-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-icon-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-hover-state-layer-color: #1c1d1c;
  --mdc-checkbox-selected-pressed-state-layer-color: #1c1d1c;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.theme-dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d7af6;
  --mdc-checkbox-selected-hover-icon-color: #5d7af6;
  --mdc-checkbox-selected-icon-color: #5d7af6;
  --mdc-checkbox-selected-pressed-icon-color: #5d7af6;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-hover-state-layer-color: #5d7af6;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d7af6;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e01e5a;
  --mdc-checkbox-selected-hover-icon-color: #e01e5a;
  --mdc-checkbox-selected-icon-color: #e01e5a;
  --mdc-checkbox-selected-pressed-icon-color: #e01e5a;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-hover-state-layer-color: #e01e5a;
  --mdc-checkbox-selected-pressed-state-layer-color: #e01e5a;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark {
  --mdc-checkbox-state-layer-size: 40px;
}

.theme-dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #5d7af6;
}
.theme-dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1c1d1c;
}
.theme-dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e01e5a;
}
.theme-dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #5d7af6;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1c1d1c;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e01e5a;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #5d7af6;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1c1d1c;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e01e5a;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #5d7af6;
}
.theme-dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1c1d1c;
}
.theme-dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e01e5a;
}
.theme-dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-mdc-button, .theme-dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-button.mat-primary, .theme-dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #5d7af6;
  --mat-mdc-button-ripple-color: rgba(93, 122, 246, 0.1);
}
.theme-dark .mat-mdc-button.mat-accent, .theme-dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1c1d1c;
  --mat-mdc-button-ripple-color: rgba(28, 29, 28, 0.1);
}
.theme-dark .mat-mdc-button.mat-warn, .theme-dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e01e5a;
  --mat-mdc-button-ripple-color: rgba(224, 30, 90, 0.1);
}
.theme-dark .mat-mdc-raised-button, .theme-dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-raised-button.mat-primary, .theme-dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-raised-button.mat-accent, .theme-dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-raised-button.mat-warn, .theme-dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-button.mat-mdc-button-base,
.theme-dark .mat-mdc-raised-button.mat-mdc-button-base,
.theme-dark .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-dark .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.theme-dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.theme-dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.theme-dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.theme-dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #5d7af6;
  --mat-mdc-button-persistent-ripple-color: #5d7af6;
  --mat-mdc-button-ripple-color: rgba(93, 122, 246, 0.1);
}
.theme-dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1c1d1c;
  --mat-mdc-button-persistent-ripple-color: #1c1d1c;
  --mat-mdc-button-ripple-color: rgba(28, 29, 28, 0.1);
}
.theme-dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e01e5a;
  --mat-mdc-button-persistent-ripple-color: #e01e5a;
  --mat-mdc-button-ripple-color: rgba(224, 30, 90, 0.1);
}
.theme-dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}
.theme-dark .mat-mdc-fab, .theme-dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-dark .mat-mdc-fab.mat-primary, .theme-dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-fab.mat-accent, .theme-dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-fab.mat-warn, .theme-dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-dark .mat-mdc-fab.mat-unthemed, .theme-dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #424242;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-dark .mat-mdc-fab.mat-primary, .theme-dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #5d7af6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-dark .mat-mdc-fab.mat-accent, .theme-dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1c1d1c;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-dark .mat-mdc-fab.mat-warn, .theme-dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #e01e5a;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-dark .mat-mdc-fab[disabled][disabled], .theme-dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.theme-dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #424242);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #424242);
}
.theme-dark .mdc-data-table__row--selected {
  background-color: rgba(93, 122, 246, 0.04);
}
.theme-dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mdc-data-table__cell,
.theme-dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-dark .mdc-data-table__pagination-total,
.theme-dark .mdc-data-table__pagination-rows-per-page-label,
.theme-dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-dark .mat-mdc-table {
  background: #424242;
}
.theme-dark .mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.theme-dark .mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.theme-dark .mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}
.theme-dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #5d7af6;
}
.theme-dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1c1d1c;
}
.theme-dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #e01e5a;
}
.theme-dark .mat-badge-content {
  color: white;
  background: #5d7af6;
}
.cdk-high-contrast-active .theme-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-dark .mat-badge-accent .mat-badge-content {
  background: #1c1d1c;
  color: white;
}
.theme-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e01e5a;
}
.theme-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.theme-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.theme-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.theme-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.theme-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.theme-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.theme-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.theme-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.theme-dark .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.theme-dark .mat-calendar-arrow {
  fill: white;
}
.theme-dark .mat-datepicker-toggle,
.theme-dark .mat-datepicker-content .mat-calendar-next-button,
.theme-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-calendar-table-header,
.theme-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-calendar-body-cell-content,
.theme-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.theme-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-calendar-body-in-range::before {
  background: rgba(93, 122, 246, 0.2);
}
.theme-dark .mat-calendar-body-comparison-identical,
.theme-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-dark .mat-calendar-body-comparison-bridge-start::before,
.theme-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 122, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-calendar-body-comparison-bridge-end::before,
.theme-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 122, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-dark .mat-calendar-body-selected {
  background-color: #5d7af6;
  color: white;
}
.theme-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 122, 246, 0.4);
}
.theme-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 122, 246, 0.3);
}
@media (hover: hover) {
  .theme-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(93, 122, 246, 0.3);
  }
}
.theme-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(28, 29, 28, 0.2);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(28, 29, 28, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(28, 29, 28, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1c1d1c;
  color: white;
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(28, 29, 28, 0.4);
}
.theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(28, 29, 28, 0.3);
}
@media (hover: hover) {
  .theme-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(28, 29, 28, 0.3);
  }
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(224, 30, 90, 0.2);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 30, 90, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 30, 90, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e01e5a;
  color: white;
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 30, 90, 0.4);
}
.theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 30, 90, 0.3);
}
@media (hover: hover) {
  .theme-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 30, 90, 0.3);
  }
}
.theme-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-datepicker-toggle-active {
  color: #5d7af6;
}
.theme-dark .mat-datepicker-toggle-active.mat-accent {
  color: #1c1d1c;
}
.theme-dark .mat-datepicker-toggle-active.mat-warn {
  color: #e01e5a;
}
.theme-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.theme-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.theme-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.theme-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.theme-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .theme-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.theme-dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-dark .mat-expansion-panel-header-description,
.theme-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-dark .mat-expansion-panel-header {
  height: 48px;
}
.theme-dark .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.theme-dark .mat-icon.mat-primary {
  color: #5d7af6;
}
.theme-dark .mat-icon.mat-accent {
  color: #1c1d1c;
}
.theme-dark .mat-icon.mat-warn {
  color: #e01e5a;
}
.theme-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.theme-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.theme-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.theme-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.theme-dark .mat-step-header.cdk-keyboard-focused, .theme-dark .mat-step-header.cdk-program-focused, .theme-dark .mat-step-header:hover:not([aria-disabled]), .theme-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-dark .mat-step-header:hover {
    background: none;
  }
}
.theme-dark .mat-step-header .mat-step-label,
.theme-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-dark .mat-step-header .mat-step-icon-selected,
.theme-dark .mat-step-header .mat-step-icon-state-done,
.theme-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #5d7af6;
  color: white;
}
.theme-dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1c1d1c;
  color: white;
}
.theme-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e01e5a;
  color: white;
}
.theme-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e01e5a;
}
.theme-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e01e5a;
}
.theme-dark .mat-stepper-horizontal, .theme-dark .mat-stepper-vertical {
  background-color: #424242;
}
.theme-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-horizontal-stepper-header::before,
.theme-dark .mat-horizontal-stepper-header::after,
.theme-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .mat-horizontal-stepper-header {
  height: 72px;
}
.theme-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.theme-dark .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.theme-dark .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.theme-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .theme-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.theme-dark .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.theme-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.theme-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.theme-dark .mat-toolbar.mat-primary {
  background: #5d7af6;
  color: white;
}
.theme-dark .mat-toolbar.mat-accent {
  background: #1c1d1c;
  color: white;
}
.theme-dark .mat-toolbar.mat-warn {
  background: #e01e5a;
  color: white;
}
.theme-dark .mat-toolbar .mat-form-field-underline,
.theme-dark .mat-toolbar .mat-form-field-ripple,
.theme-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-dark .mat-toolbar .mat-form-field-label,
.theme-dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-dark .mat-toolbar .mat-select-value,
.theme-dark .mat-toolbar .mat-select-arrow,
.theme-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-dark .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.theme-dark .mat-toolbar-row, .theme-dark .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .theme-dark .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .theme-dark .mat-toolbar-row, .theme-dark .mat-toolbar-single-row {
    height: 56px;
  }
}
.theme-dark .mat-tree {
  background: #424242;
}
.theme-dark .mat-tree-node,
.theme-dark .mat-nested-tree-node {
  color: white;
}
.theme-dark .mat-tree-node {
  min-height: 48px;
}
.theme-dark .mat-mdc-menu-content {
  background: unset !important;
}
.theme-dark .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 1.166666em !important;
  --mdc-icon-button-icon-size: 1.166666em !important;
  background-color: unset !important;
  color: unset !important;
  border-color: unset !important;
  font-size: inherit;
  padding: var(--spacing-small);
  box-sizing: content-box;
  line-height: 1;
}

/* You can add global styles to this file, and also import other style files */
:root {
  font-size: calc((0.9vmax + 18px) / 2);
  margin: 0;
  font-family: Lato, "Helvetica Neue", sans-serif;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
  caret-color: currentColor;
  --fontColor: #707fff;
  --charts-font: #000000;
  --sidebar-background:#5d7af6;
  --charts-background: #ffffff;
  --highcharts-labels:#777;
  --scroll-bar:#e3e7f8;
  --mat-toolbar:transparent;
  --dialog-container-background: #424242;
  --spacing-small: calc((0.5em + 0.5rem)/2);
  --spacing-medium: calc((1em + 1rem)/2);
  --spacing-large: calc((2em + 2rem)/2);
  --action-button:rgba(227, 231, 248, 0.5);
  --corner-radius: calc((0.223em + 0.223rem)/2);
  --svg-filter:"";
  --popup-background: rgb(231, 231, 245) !important;
  --popup-color:#000000;
  --shadow-blur: calc((0.4em + 0.4rem)/2);
  --shadow-color: rgba(0,0,0,0.5);
  --view-button:rgba(227, 231, 248, 0.5);
  --page-background: #fafafa;
  --panel-background: rgba(255, 255, 255, 0.085);
  --panel-background-solid: white;
  --dialog-background: white;
  --divider-color: rgba(0, 0, 0, 0.12);
  --density-background: #ffffff;
  --primary-text-color: rgba(0, 0, 0, 0.87);
  --secondary-text-color: rgba(0, 0, 0, 0.54);
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  transition: background-color 5000s ease-in-out 0s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1rem;
}

a {
  color: inherit;
}
a:active {
  color: inherit;
  opacity: 1;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-small.font-small {
  font-size: 0.8rem;
}

.font-medium.font-medium {
  font-size: 1rem;
}

.font-large.font-large {
  font-size: 1.2rem;
}

.bold {
  font-weight: bold !important;
}

.italic {
  font-style: italic;
}

.min-content-width {
  width: min-content;
}

.full-width {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.fill-available-width {
  width: -webkit-fill-available !important;
}

.max-content-width {
  width: max-content;
}

.full-height {
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.half-height {
  height: 50%;
  max-height: 50%;
  box-sizing: border-box;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-hidden {
  overflow: hidden;
}

.grid-column {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.flex-column.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column.flex-column.center {
  justify-content: center;
}
.flex-column.flex-column.justify {
  justify-content: space-between;
}
.flex-column.flex-column.even {
  justify-content: space-evenly;
}
.flex-column.flex-column.around {
  justify-content: space-around;
}
.flex-column.flex-column.top {
  align-items: flex-start;
}
.flex-column.flex-column.middle {
  align-items: center;
}
.flex-column.flex-column.bottom {
  align-items: end;
}
.flex-column.flex-column.fill > * {
  flex-grow: 1;
}
.flex-column.flex-column.gap-small {
  gap: var(--spacing-small);
}
.flex-column.flex-column.gap-medium {
  gap: var(--spacing-medium);
}
.flex-column.flex-column.gap-large {
  gap: var(--spacing-large);
}
.flex-column.flex-column.wrap {
  flex-wrap: wrap;
}
.flex-column.flex-column > .flex-size-fill {
  height: 0;
}

.flex-row.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row.flex-row .container-50 {
  width: 50%;
}
.flex-row.flex-row.center {
  justify-content: center;
}
.flex-row.flex-row.align-center {
  align-items: center;
}
.flex-row.flex-row.justify {
  justify-content: space-between;
}
.flex-row.flex-row.even {
  justify-content: space-evenly;
}
.flex-row.flex-row.around {
  justify-content: space-around;
}
.flex-row.flex-row.end {
  justify-content: flex-end;
}
.flex-row.flex-row.top {
  align-items: flex-start;
}
.flex-row.flex-row.middle {
  align-items: center;
}
.flex-row.flex-row.bottom {
  align-items: end;
}
.flex-row.flex-row.fill > * {
  flex-grow: 1;
}
.flex-row.flex-row.gap-small {
  gap: var(--spacing-small);
}
.flex-row.flex-row.gap-medium {
  gap: var(--spacing-medium);
}
.flex-row.flex-row.gap-large {
  gap: var(--spacing-large);
}
.flex-row.flex-row.wrap {
  flex-wrap: wrap;
}
.flex-row.flex-row > .flex-size-fill {
  width: 0;
}

@media (max-width: 1100px) {
  .content {
    flex: 100%;
  }
}
@media (max-width: 1720px) {
  .content {
    flex: 100%;
  }
  .grid-2xn {
    grid-template-columns: 50% 50% !important;
  }
}
.flex-size-fill {
  flex: 1 1 0;
}

.grid-2x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.grid-2x2.gap-small {
  gap: var(--spacing-small);
}
.grid-2x2.gap-medium {
  gap: var(--spacing-medium);
}
.grid-2x2.gap-large {
  gap: var(--spacing-large);
}

.grid-2xn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  min-height: 100vh;
  text-align: center;
  overflow-x: hidden;
}
.grid-2xn .container {
  height: auto;
}

@media (max-width: 770px) {
  .grid-2xn {
    grid-template-columns: 1fr !important;
  }
}
.grid-3xn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: dense;
  text-align: center;
}
.grid-3xn .container {
  height: auto;
}

@media (max-width: 1023px) {
  .grid-3xn {
    grid-template-columns: 1fr 1fr !important;
  }
}
.grid-2x1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
.grid-2x1.gap-small {
  gap: var(--spacing-small);
}
.grid-2x1.gap-medium {
  gap: var(--spacing-medium);
}
.grid-2x1.gap-large {
  gap: var(--spacing-large);
}

.grid-3x2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.grid-3x2.gap-small {
  gap: var(--spacing-small);
}
.grid-3x2.gap-medium {
  gap: var(--spacing-medium);
}
.grid-3x2.gap-large {
  gap: var(--spacing-large);
}

.card {
  background-color: var(--panel-background);
  border-radius: 0.25rem;
  padding: var(--spacing-medium);
  box-shadow: 0px -2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.12) !important;
}

.section {
  margin: 0rem 2rem;
}
.section.start {
  padding-top: 1rem;
}
.section.end {
  padding-bottom: 1rem;
}

.container {
  padding: 1rem;
}

.container-graph {
  width: 100%;
  margin-top: -1rem;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.blocks-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1100px) {
  .flex-wrapper {
    display: flex;
    flex-direction: column;
  }
  .flex-wrapper .content {
    width: 100%;
  }
  .blocks-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 450px) {
  .flex-percent {
    display: flex;
    flex-direction: column;
  }
  .flex-percent .container-50 {
    width: auto !important;
  }
}
.mt-20 {
  margin-top: 1rem;
}

.mt-15 {
  margin-top: 0.8rem;
}

.flex-percent {
  display: flex;
}
.flex-percent .container-33 {
  width: 33%;
}
.flex-percent .container-67 {
  width: 64%;
}
.flex-percent .container-50 {
  width: 50%;
}
.flex-percent .container-20 {
  width: 20%;
}
.flex-percent .container-80 {
  width: 78%;
}
@media (max-width: 1100px) {
  .flex-percent .container-33 {
    width: auto;
  }
  .flex-percent .container-67 {
    width: auto;
  }
}

.button {
  padding: 1rem 2rem;
  align-items: center;
  font-weight: 600;
  font-size: inherit;
}

.image-label {
  width: 10rem;
  position: absolute;
  margin-bottom: 2rem;
  left: 10%;
  transform: translateX(-50%);
  z-index: 2;
}

.plot svg {
  left: 0px;
}

.spinner-container {
  width: --webkit-fill-available;
  padding-block: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-pickers {
  margin: 0.5rem;
}
.mat-pickers.medium {
  width: 12%;
  height: 20px;
  margin: 10px;
}
.mat-pickers.medium mat-select {
  width: 18ch;
}
.mat-pickers.small {
  width: 8%;
  height: 20px;
  margin: 10px;
}
.mat-pickers.small mat-select {
  width: 11ch;
}

.not-found-svg {
  width: 80px !important;
  height: 80px !important;
  padding: 20px !important;
}

.popup {
  border-radius: 2rem !important;
  font-weight: 400;
  background-color: var(--popup-background) !important;
  padding: var(--spacing-medium);
  display: flex;
  color: var(--popup-color);
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-medium);
}

.action-button {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
  border: none !important;
  margin: 0.5rem !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem !important;
  color: var(--fontColor);
  background: var(--action-button) !important;
}

.mat-icon-svg {
  margin-left: 1rem !important;
  color: var(--fontColor);
}

.mat-mdc-card {
  background-color: var(--panel-background) !important;
  box-shadow: 0px -2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.12) !important;
}

.highcharts-axis-title {
  fill: var(--highcharts-labels) !important;
  color: var(--highcharts-labels) !important;
}

.highcharts-axis-labels,
.highcharts-yaxis-labels {
  fill: var(--highcharts-labels) !important;
}

.highcharts-yaxis-labels text {
  fill: var(--highcharts-labels) !important;
  cursor: default;
}

.highcharts-xaxis-labels text {
  fill: var(--highcharts-labels) !important;
}

.highcharts-legend text {
  fill: var(--highcharts-labels) !important;
}

.mat-button-toggle-label-content {
  padding: 0px !important;
}

.table-container {
  display: flex;
  align-content: stretch;
}
.table-container .table {
  flex: 1;
  width: 100%;
}
.table-container .table.small {
  padding: 0.3rem;
}
.table-container .table.normal {
  padding: 1rem;
}
.table-container .table.horizontal.start {
  margin-right: 1rem;
}
.table-container .table.horizontal.middle {
  margin-inline: 1rem;
}
.table-container .table.horizontal.end {
  margin-left: 1rem;
}
.table-container .table.vertical {
  margin-left: 0.7rem;
}
.table-container .table.vertical.start {
  margin-bottom: 1rem;
}
.table-container .table.vertical.middle {
  margin-block: 1rem;
}
.table-container .table.vertical.end {
  margin-top: 1rem;
}
.table-container .table .table-title {
  font-size: 1rem;
  margin-left: 1.5rem;
  padding: 0.5rem;
}
.table-container .table .row-small {
  min-height: 16px !important;
}

.payload-timeline-container {
  position: absolute;
  height: 3.5rem;
  display: flex;
  width: 100%;
  z-index: 2;
  justify-content: space-between;
}
.payload-timeline-container .payload-header {
  margin: 1rem;
}
.payload-timeline-container .payload-header .quantity-background {
  background-color: var(--charts-background);
}

.mat-mdc-button.mat-mdc-button-base {
  font-size: inherit;
  line-height: normal;
  min-width: 0;
  padding: var(--spacing-small);
  background: var(--panel-background);
  height: auto;
}
.mat-mdc-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  width: calc(100% + var(--spacing-small) * 2);
  height: calc(100% + var(--spacing-small) * 2);
  top: 50%;
  left: 50%;
  right: none;
  transform: translate(-50%, -50%);
}
.mat-mdc-button.mat-mdc-button-base mat-icon {
  margin-right: var(--spacing-small);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 1.166666em;
  --mdc-icon-button-icon-size: 1.166666em;
  font-size: inherit;
  padding: var(--spacing-small);
  box-sizing: content-box;
  line-height: 1;
}
.mat-mdc-icon-button.mat-mdc-button-base > .mat-icon, .mat-mdc-icon-button.mat-mdc-button-base > svg {
  display: block;
}
.mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  width: calc(100% + var(--spacing-small) * 2);
  height: calc(100% + var(--spacing-small) * 2);
}
.mat-mdc-icon-button.mat-mdc-button-base.inline {
  margin: -0.35em;
}

.mat-button-toggle-group {
  height: 30px;
}

.mat-button-toggles {
  margin: 1rem;
}
.mat-button-toggles .mat-button-toggle-checked {
  background-color: var(--sidebar-background);
  color: white;
}
.mat-button-toggles .mat-button-toggle {
  width: 4.5rem;
  height: 2.2rem;
}

mat-calendar.mat-calendar {
  width: calc(var(--spacing-medium) * 9 + 18ch);
  padding: 20px;
  border-radius: 28px;
  font-weight: 400;
}
mat-calendar.mat-calendar .mat-calendar-header {
  padding-top: var(--spacing-small);
  padding-left: var(--spacing-small);
  padding-right: var(--spacing-small);
}
mat-calendar.mat-calendar .mat-calendar-header .mat-calendar-controls {
  margin-top: 0;
  margin-bottom: 0;
  gap: var(--spacing-small);
}
mat-calendar.mat-calendar .mat-calendar-header .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 0.8em;
  padding: var(--spacing-small);
}
mat-calendar.mat-calendar .mat-calendar-header .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base::after {
  margin: 0;
  top: 30%;
  right: 30%;
  bottom: 30%;
  left: 30%;
}
mat-calendar.mat-calendar .mat-calendar-table-header th {
  padding-bottom: var(--spacing-medium);
}
mat-calendar.mat-calendar .mat-calendar-content {
  padding: 0;
}
mat-calendar.mat-calendar .mat-calendar-table {
  margin-top: 1em;
  font-size: 12px;
  font-weight: 400;
}
mat-calendar.mat-calendar .mat-calendar-body-label {
  height: 0;
  line-height: 0;
  text-align: left;
  color: transparent;
  padding-left: 4.7142857143%;
  padding-right: 4.7142857143%;
}

:host {
  padding: 1rem;
}

.mat-mdc-dialog-container {
  max-width: 90vw !important;
  max-height: 90vh !important;
}
.mat-mdc-dialog-container .title {
  font-weight: 500;
  font-size: larger;
  padding: 1rem;
  background-color: var(--sidebar-background);
  color: var(--scroll-bar);
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
  overflow: inherit;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface ::-webkit-scrollbar:horizontal {
  display: none;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * {
  display: contents;
  padding: var(--spacing-medium);
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-content {
  min-width: 20rem;
  padding: 1rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-content label {
  font-size: 0.75rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-content .content-container .scroll {
  overflow-y: scroll;
  overflow-x: none;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-content .flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: inherit;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-content .input-half {
  width: 50%;
  padding-inline: 0.5rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-content mat-form-field {
  width: 100%;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-actions {
  display: flex;
  place-content: flex-end;
  padding: 0.5rem;
  margin-top: var(--spacing-medium);
  margin-bottom: 0;
  min-height: 0;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-actions button {
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface > * .mat-mdc-dialog-actions .confirm {
  background-color: #E3E7F8;
  color: #5D7AF6;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface .report .mat-mdc-dialog-content {
  max-width: 100rem !important;
}

mat-form-field.mat-mdc-form-field {
  --padding-horz: calc((0.5em + 0.5em)/2);
  --padding-vert: calc((0.5em + 0.5em)/2);
  --label-font-size: calc((0.66em + 0.66rem)/2);
  --mat-mdc-form-field-floating-label-scale: calc(var(--label-font-size)/1em);
  --label-transition: cubic-bezier(0.4, 0, 0.2, 1);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline {
  border-top: solid transparent calc(var(--label-font-size) / 2);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper {
  padding-left: var(--padding-horz);
  padding-right: var(--padding-horz);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
  width: var(--padding-horz);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch {
  max-width: 100%;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex .mat-mdc-floating-label {
  font-size: 1em;
  user-select: none;
  display: block;
  top: 50%;
  height: auto;
  left: 0;
  transform: translateY(-50%);
  transition: font-size 150ms var(--label-transition), height 150ms var(--label-transition), top 150ms var(--label-transition), left 150ms var(--label-transition), color 150ms var(--label-transition);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex .mat-mdc-floating-label.mdc-floating-label--float-above {
  font-size: var(--label-font-size);
  height: 1em;
  top: 0%;
  left: 4px;
  transform: translateY(-50%);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  padding-top: var(--padding-vert);
  padding-bottom: var(--padding-vert);
  min-height: 0;
  width: auto;
  line-height: 1;
  input-height: 1em;
  input-line-height: normal;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix input:-internal-autofill-selected, mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix input:-webkit-autofill {
  background-color: transparent !important;
  color: black !important;
  -webkit-text-fill-color: black;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-icon-suffix {
  padding-left: var(--padding-horz);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-icon-suffix .mat-icon, mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-icon-suffix svg {
  padding: 0;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-icon-suffix .mdc-icon-button {
  margin: calc(var(--spacing-small) * -1);
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-form-field-subscript-wrapper {
  font-size: 60%;
  line-height: 1.125em;
  padding-top: 0.25em;
  padding-bottom: 0.5em;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before {
  display: none;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-form-field-subscript-wrapper > .mat-mdc-form-field-hint-wrapper, mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline > .mat-mdc-form-field-subscript-wrapper > .mat-mdc-form-field-error-wrapper {
  position: relative;
}

.mat-mdc-input-element {
  line-height: normal;
}

.mat-mdc-select-trigger {
  gap: 1ch;
}

.mat-icon.mat-icon {
  display: inline-block;
  font-size: 1.166666em;
  line-height: 1em;
  overflow: hidden;
}

.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: var(--spacing-small) 0;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
  min-height: 0;
  padding: var(--spacing-small) var(--spacing-medium);
}

.mat-mdc-paginator {
  border-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--divider-color);
}
.mat-mdc-paginator .mat-paginator-container {
  min-height: 0;
  padding: var(--spacing-small);
}
.mat-mdc-paginator .mat-mdc-paginator-page-size {
  margin-right: var(--spacing-medium);
}
.mat-mdc-paginator .mat-mdc-paginator-page-size-select {
  width: 8ch;
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions {
  color: var(--primary-text-color);
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
  margin: 0;
  color: var(--secondary-text-color);
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions button {
  margin-left: var(--spacing-medium);
  padding: 0;
  font-size: 150%;
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions button:last-child {
  margin-left: var(--spacing-small);
}

mat-select .mat-mdc-select-trigger {
  gap: var(--spacing-small);
}
mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  width: auto;
  height: 1.166666em;
}

.mat-mdc-select-panel mat-option.mat-mdc-option {
  padding: var(--spacing-small);
  min-height: 0;
}
.mat-mdc-select-panel mat-option.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 0;
}

mat-spinner.inline {
  width: 1em !important;
  height: 1em !important;
}
mat-spinner.inline > svg {
  width: 1em !important;
  height: 1em !important;
}

.data-table-caption, .data-table-settings {
  padding: 0.75em 1em;
  text-align: left;
  background-color: var(--panel-background);
  border-radius: var(--corner-radius);
  caption-side: top;
  border-radius: var(--corner-radius);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--divider-color);
}

.data-table-caption .title {
  font-size: 1.2em;
}

.data-table-scroller {
  background: var(--panel-background);
  border-radius: var(--corner-radius);
  flex: 1 1 0;
  height: 0;
  overflow: auto;
}
.data-table-scroller table.data {
  background: none;
}
.data-table-scroller table.data thead > tr > th, .data-table-scroller table.data tbody > tr > th {
  background: var(--panel-background-solid);
}

.data-table-scroller::-webkit-scrollbar-track {
  background: var(--panel-background);
  background-color: #424242;
}

table.data {
  background: var(--panel-background);
  border-spacing: 0;
  border-radius: var(--corner-radius);
}
table.data > caption, table.data > thead > tr > th, table.data > tbody > tr > td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--divider-color);
}
table.data > caption {
  font-size: 1.2em;
  padding: var(--spacing-medium) var(--spacing-large);
  text-align: left;
  background: var(--panel-background);
  border-radius: var(--corner-radius);
  caption-side: top;
}
table.data > thead {
  position: relative;
  top: 0;
  z-index: 1;
}
table.data thead > tr > th, table.data tbody > tr > th {
  font-weight: normal;
  color: var(--secondary-text-color);
  text-align: left;
}
table.data thead > tr > th, table.data thead > tr > td, table.data tbody > tr > th, table.data tbody > tr > td {
  padding: var(--spacing-small);
}
table.data thead > tr > th:first-child, table.data thead > tr > td:first-child, table.data tbody > tr > th:first-child, table.data tbody > tr > td:first-child {
  padding-left: var(--spacing-large);
}
table.data thead > tr > th:last-child, table.data thead > tr > td:last-child, table.data tbody > tr > th:last-child, table.data tbody > tr > td:last-child {
  padding-right: var(--spacing-large);
}
table.data tbody > mat-spinner {
  font-size: 4em;
}
table.data tbody:empty {
  font-style: italic;
  display: block;
  padding: 0.75em 1.5em;
}
table.data tbody:empty::before {
  content: "None";
  opacity: 0.5;
}
table.data.loading tbody:empty::before {
  content: "Loading";
}

table.labeled {
  border-spacing: 0;
  background: var(--panel-background);
  border-radius: var(--corner-radius);
  padding: 0 var(--spacing-small);
}
table.labeled th, table.labeled td {
  padding: var(--spacing-small);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--divider-color);
}
table.labeled tr:last-child > th, table.labeled tr:last-child > td {
  border-bottom-style: none;
}
table.labeled th {
  padding-left: 0;
  font-weight: normal;
  color: var(--secondary-text-color);
  text-align: left;
}
table.labeled td {
  padding-right: 0;
  color: var(--primary-text-color);
  text-align: right;
}

::-webkit-scrollbar-button:single-button:vertical:increment, ::-webkit-scrollbar-button:single-button:vertical:decrement {
  display: none;
}

::-webkit-scrollbar {
  width: 0.4rem !important;
}

::-webkit-scrollbar-track {
  background: #e3e7f8;
}

::-webkit-scrollbar-thumb {
  border-color: #e3e7f8;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.15rem;
  background: #5d7af6;
}

::-webkit-scrollbar-button:single-button {
  background-color: #e3e7f8;
  display: block;
  border-style: solid;
  border: radius 0.15rem;
}

.mat-simple-snackbar {
  font-size: 1.2em;
  border-radius: 0.5rem;
  position: relative !important;
  bottom: 3.25rem !important;
  right: 6.5rem !important;
}

.snackbar-error {
  background-color: #E01E5A;
}

.snackbar-success {
  background-color: #707FFF;
}

.snackbar-info {
  background-color: #1C1D1C;
}

.mdc-snackbar__surface {
  background-color: var(--color-primary) !important;
}

.mdc-snackbar__label {
  color: white !important;
  font-size: 1.2em;
}

.auth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth-content .input-wrapper {
  position: relative;
  width: -webkit-fill-available;
}
.auth-content .input-wrapper label {
  display: flex;
  align-items: start;
  color: #727972;
}
.auth-content .input-wrapper mat-form-field {
  width: -webkit-fill-available;
}
.auth-content .input-wrapper span {
  position: absolute;
  right: 0.5rem;
  color: #707fff;
  font-size: 1rem;
  font-weight: 400;
}
.auth-content img {
  margin: 2.5rem;
}
.auth-content h4 {
  font-weight: 500;
}
.auth-content p {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  color: #727972;
}
.auth-content p span {
  color: #707fff;
}
.auth-content button {
  margin-block: 2.5rem;
  padding-inline: 5rem;
  padding-block: 1.5rem;
}

report, report-v2 {
  display: block;
  width: 100%;
  height: 100%;
}

.page-list report-page {
  background-color: white;
  color: black;
  font-size: 8.5pt;
  line-height: 1.3em;
  font-family: "Helvetica";
  padding: 7mm;
  height: 11in;
  width: 8.5in;
  min-width: 8.5in;
  max-width: 8.5in;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transform-origin: top left;
}
.page-list report-page > .content-window {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
}
.page-list report-page > .content-window > .content {
  position: relative;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
.page-list report-page > .content-window > .markup-annotations {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}
.page-list report-page > .content-window > .annotate {
  background-color: rgba(0, 0, 0, 0.1254901961);
}
.page-list report-page report-header, .page-list report-page .report-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2mm;
}
.page-list report-page report-header.has-sub-title, .page-list report-page .report-header.has-sub-title {
  margin-bottom: calc(2mm + 1.5em);
}
.page-list report-page report-header .title, .page-list report-page .report-header .title {
  font-size: 2em;
  line-height: 1em;
}
.page-list report-page report-header .title *, .page-list report-page .report-header .title * {
  vertical-align: baseline;
  vertical-align: top;
  vertical-align: bottom;
  vertical-align: text-top;
  vertical-align: text-bottom;
  vertical-align: middle;
}
.page-list report-page report-header .title mat-icon, .page-list report-page .report-header .title mat-icon {
  margin-left: -0.1em;
  font-size: inherit !important;
  height: 1em;
  width: 1em;
  color: var(--primary-color);
}
.page-list report-page report-header .title .report-name, .page-list report-page .report-header .title .report-name {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
  position: relative;
}
.page-list report-page report-header .title .project-name, .page-list report-page .report-header .title .project-name {
  font-size: 0.6em;
  line-height: 1em;
}
.page-list report-page report-header .company, .page-list report-page .report-header .company {
  font-size: 2em;
  line-height: 1em;
}
.page-list report-page report-header .company > *, .page-list report-page .report-header .company > * {
  vertical-align: middle;
}
.page-list report-page report-header .company img, .page-list report-page .report-header .company img {
  height: 1em;
}
.page-list report-page report-header .sub-title, .page-list report-page .report-header .sub-title {
  position: absolute;
  left: 2em;
  top: calc(2em + 1mm);
}
.page-list report-page report-header .sub-title > span, .page-list report-page .report-header .sub-title > span {
  font-size: 1.5em;
}
.page-list report-page .strip {
  height: 10px;
}
.page-list report-page .strip.bar-1 {
  background-color: #5636ff;
}
.page-list report-page .strip.bar-2 {
  background-color: #5355ff;
}
.page-list report-page .strip.bar-3 {
  background-color: #4f92ff;
}
.page-list report-page report-page-footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 0px;
  line-height: 1em;
  margin-top: 1em;
}
.page-list report-page report-page-footer > .left > * {
  vertical-align: middle;
}
.page-list report-page report-page-footer > .left mat-icon {
  margin-left: -0.1em;
  margin-right: -0.1em;
  font-size: inherit !important;
  height: 1em;
  width: 1em;
  color: var(--primary-color);
}
.page-list report-page report-page-footer > .left img {
  height: 1em;
}
.page-list report-page report-page-footer > .page-number {
  font-weight: bold;
  text-align: right;
}
.page-list report-page .graph {
  position: relative;
}
.page-list report-page .graph::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
}
.page-list report-page.summary .content {
  font-size: 14pt;
  line-height: 1.3em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.page-list report-page.summary .content .box {
  padding: 0.66em 1em;
  margin-bottom: 0;
}
.page-list report-page.summary .content .box > table.data {
  margin-top: -0.3em;
  margin-left: -1em;
  margin-right: -1em;
  width: calc(100% + 2em);
}
.page-list report-page.summary .content .box > table.data th, .page-list report-page.summary .content .box > table.data td {
  padding: 0.3em 0.3em 0.15em;
  border-color: rgba(0, 0, 0, 0.3333333333);
}
.page-list report-page.summary .content .box > table.data th:first-child, .page-list report-page.summary .content .box > table.data td:first-child {
  border-left: none;
  padding-left: 1em;
}
.page-list report-page.summary .content .box > table.data th:last-child, .page-list report-page.summary .content .box > table.data td:last-child {
  border-right: none;
  padding-right: 1em;
}
.page-list report-page.summary .content .box > table.data tr:first-child th, .page-list report-page.summary .content .box > table.data tr:first-child td {
  border-top: none;
  background-color: transparent;
}
.page-list report-page.summary .content .box > table.data tr:last-child th, .page-list report-page.summary .content .box > table.data tr:last-child td {
  border-bottom: none;
}
.page-list report-page.summary .content report-header {
  font-size: 0.5em;
}
.page-list report-page.summary .content report-header .sub-title > span {
  font-size: 2em;
}
.page-list report-page.summary .content report-page-footer {
  font-size: 0.5em;
}
.page-list report-page.summary .content > .one-column {
  width: fit-content;
  min-width: 70%;
  max-width: 100%;
  padding: 0 1em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.page-list report-page.summary .content > .two-column {
  padding: 0 1em;
  width: fit-content;
  min-width: 70%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template: ". .";
  gap: 1em;
}
.page-list report-pages {
  display: contents;
}
.page-list report-pages report-page > .content-window {
  flex-grow: 0;
}
.page-list report-pages report-page > .content-window > .content {
  height: auto;
  max-height: none;
}

@media screen {
  report report-page-container {
    width: 0;
  }
  report-v2 report-page-container {
    height: 0;
    width: 100%;
  }
  report, report-v2 {
    display: flex;
    flex-direction: row;
    position: absolute;
  }
  report > .controls, report-v2 > .controls {
    position: relative;
    width: 72.76mm;
    padding: var(--spacing-medium);
    overflow-x: hidden;
    overflow-y: auto;
  }
  report report-page-container, report-v2 report-page-container {
    display: block;
    flex: 1 1 0;
    position: relative;
  }
  report report-page-container > .page-scroller, report-v2 report-page-container > .page-scroller {
    background-color: grey;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: auto;
    position: relative;
    padding: var(--spacing-large);
  }
  report report-page-container > .page-scroller > .page-scroller-inner, report-v2 report-page-container > .page-scroller > .page-scroller-inner {
    height: 100%;
    max-height: 100%;
    width: fit-content;
  }
  report report-page-container .page-scroller::-webkit-scrollbar-button:single-button, report-v2 report-page-container .page-scroller::-webkit-scrollbar-button:single-button {
    display: none;
  }
  report report-page-container > .scroll-buttons, report-v2 report-page-container > .scroll-buttons {
    position: absolute;
    bottom: calc(1.6rem + var(--spacing-medium));
    left: var(--spacing-medium);
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    pointer-events: none;
  }
  report report-page-container > .scroll-buttons > *, report-v2 report-page-container > .scroll-buttons > * {
    box-shadow: rgba(0, 0, 0, 0.533) 0px 0px 4px;
    pointer-events: all;
  }
  report .icon-box, report-v2 .icon-box {
    margin: 0rem 1rem 1rem 0rem;
    display: flex;
    width: 5rem;
    height: 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e3e7f8;
    box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(227, 231, 248, 0.5);
    cursor: pointer;
  }
  report .icon-box.active, report-v2 .icon-box.active {
    border: 2px solid #5d7af6;
  }
  report .icon-box span, report-v2 .icon-box span {
    font-size: 0.5rem;
  }
  report .actions, report-v2 .actions {
    display: flex;
    place-content: flex-end;
    padding: 0;
    margin-top: var(--spacing-large);
    margin-bottom: 0;
    min-height: 0;
    position: absolute;
    bottom: var(--spacing-small);
    right: var(--spacing-small);
  }
  report .actions button, report-v2 .actions button {
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
  }
  report .actions .confirm, report-v2 .actions .confirm {
    background-color: #E3E7F8;
    color: #5D7AF6;
  }
}
.page-list {
  width: fit-content;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-large);
  align-items: center;
  background-color: grey;
}
.page-list.zoom-fit {
  height: 100%;
  max-height: 100%;
}
.page-list.zoom-rows2 {
  display: grid;
  grid-template-areas: ". .";
  grid-auto-flow: column;
  grid-template-rows: repeat(2, auto);
  justify-items: center;
}

.cdk-virtual-scroll-content-wrapper {
  gap: var(--spacing-large);
  display: flex;
}

@media print {
  .no-pdf {
    display: none !important;
  }
  iframe#launcher {
    display: none;
  }
  .report .controls {
    display: none;
  }
  .report .page-list {
    transform: none !important;
  }
  .report .header {
    display: none;
  }
  .report > .controls {
    display: none;
  }
  .report .pages-and-controls > .page-list {
    background-color: transparent;
    padding: 0;
  }
  .report .pages, .report .page {
    background-color: transparent;
    padding: none;
  }
}
.report {
  --divider-color: var(--primary-text-color);
}
.report report-page .font-large.font-large.font-large {
  font-size: 1.2em;
  line-height: 1.3em;
}
.report report-page .font-medium.font-medium.font-medium {
  font-size: 1em;
  line-height: 1.3em;
}
.report report-page .font-small.font-small.font-small {
  font-size: 0.8em;
  line-height: 1.3em;
}
.report report-page .font-smaller.font-smaller.font-smaller {
  font-size: 0.4em;
  line-height: 1.3em;
}
.report report-page .avoid-page-break {
  break-inside: avoid;
}
.report report-page h1 {
  font-weight: bold;
  font-size: 1.2em;
  padding: 0;
  margin: 0;
}
.report report-page h2 {
  font-weight: bold;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}
.report report-page h3 {
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.report report-page .row {
  margin-bottom: 2mm;
}
.report report-page .box {
  background-color: white;
  padding: 2mm;
  margin-bottom: 2mm;
  border-radius: 1mm;
}
.report report-page .box .title {
  white-space: nowrap;
  text-transform: uppercase;
  padding: 0;
  margin-bottom: 0.3em;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.3333333333);
}
.report report-page .box table.label-value {
  width: 100%;
}
.report report-page .box table.label-value td:first-child {
  white-space: nowrap;
  width: 0.1rem;
}
.report report-page .box > table.label-value:not(:first-child) {
  margin-top: 0.3em;
}
.report report-page .box .flex-row {
  gap: 2mm;
}
.report report-page .label-above.label-above.label-above {
  margin-bottom: 1mm;
  line-height: 1em;
}
.report report-page .color {
  border: 0.1rem solid gray;
  box-shadow: none;
}
.report report-page span.placeholder {
  opacity: 0.66;
}
.report report-page span.placeholder::before {
  content: "<";
}
.report report-page span.placeholder::after {
  content: ">";
}
.report report-page table.data {
  border-collapse: collapse;
  border-width: 0;
}
.report report-page table.data tr {
  break-inside: avoid;
}
.report report-page table.data thead {
  break-inside: avoid;
}
.report report-page table.data th, .report report-page table.data td {
  padding: 1mm;
  border: 0.26mm solid gray;
}
.report report-page table.data td.left {
  text-align: left;
}
.report report-page table.data th {
  text-align: center;
  background-color: #eee;
  border-top-width: 0.26mm;
  font-weight: bold;
}
.report report-page table.data tr:nth-child(6n) td {
  border-top: 0.26mm solid black;
}
.report report-page table.label-value {
  border-collapse: collapse;
}
.report report-page table.label-value td {
  vertical-align: baseline;
  text-align: left;
  padding: 0;
  padding-top: 0.3em;
  box-sizing: border-box;
}
.report report-page table.label-value td > * {
  vertical-align: baseline;
}
.report report-page table.label-value td div.label-value-unaligned {
  display: flex;
  flex-direction: row;
}
.report report-page table.label-value td div.label-value-unaligned > *:first-child {
  text-transform: capitalize;
  padding-right: 1mm;
}
.report report-page table.label-value td div.label-value-unaligned > *:last-child {
  padding-left: 1mm;
  flex-grow: 1;
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0.3333333333);
}
.report report-page table.label-value td:first-child:not(:only-child) {
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 1mm;
}
.report report-page table.label-value td:last-child:not(:only-child) {
  padding-left: 1mm;
  padding-right: 1mm;
  text-align: left;
  vertical-align: bottom;
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0.3333333333);
}
.report report-page table.label-value tr:first-child th, .report report-page table.label-value tr:first-child td {
  padding-top: 0;
}
.report report-page table.label-value-data {
  border-collapse: collapse;
  border-width: 0;
}
.report report-page table.label-value-data tr {
  break-inside: avoid;
}
.report report-page table.label-value-data td {
  padding: 0.5em;
  border: 0.26mm solid gray;
}
.report report-page table.label-value-data tr:nth-child(6n) td {
  border-top: 0.26mm solid black;
}
.report report-page table.label-value-data td:first-child {
  padding-right: 0.75em;
}
.report report-page table.label-value-data td:not(:first-child) {
  padding-left: calc(0.25em + 0.2rem);
}
.report report-page table.decks {
  border-collapse: collapse;
  background-color: white;
}
.report report-page table.decks td, .report report-page table.decks th {
  padding: 0.3em 1mm 0;
  text-align: left;
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0.3333333333);
}
.report report-page table.decks th {
  background-color: #F8F8F8;
}
.report report-page table.decks td:first-child, .report report-page table.decks th:first-child {
  padding-left: 0;
}
.report report-page table.decks td:last-child, .report report-page table.decks th:last-child {
  padding-right: 0;
}
.report report-page table.decks tr:first-child th, .report report-page table.decks tr:first-child td {
  padding-top: 0;
}
.report report-page table.decks tr:last-child th, .report report-page table.decks tr:last-child td {
  border-bottom: none;
}
.report report-page div.label-value > div {
  gap: 1em;
  margin-bottom: 0;
}
.report svg {
  display: block;
}

.card-large {
  width: 8rem;
  height: 6.5rem;
  flex-shrink: 0;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  background: rgba(227, 231, 248, 0.15);
  box-shadow: none;
}
.card-large .titlespan {
  margin-left: 0.5rem;
  font-size: 90%;
}
.card-large.warn {
  background: rgba(248, 227, 227, 0.25);
}
.card-large.primary {
  background: rgba(227, 231, 248, 0.25);
}

@media (max-width: 1500px) {
  .card-large {
    width: 7rem;
    height: 5.5rem;
    margin-inline: auto;
    margin-block: 0.5rem;
    overflow: auto;
  }
  .card-large .titlespan {
    font-size: 10px;
  }
  .card-medium {
    height: 6.25rem !important;
  }
}
.card-medium {
  width: 6rem;
  height: 6.25rem;
  flex-shrink: 0;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: none;
}
.card-medium .titlespan {
  margin-left: 0.25rem;
  margin-bottom: 1rem;
  font-size: 90%;
}
.card-medium mat-icon {
  height: 1rem;
  width: 1rem;
  position: relative;
  top: 0.25rem;
  left: -0.125rem;
}
.card-medium.warn {
  background: rgba(248, 227, 227, 0.25);
}
.card-medium.primary {
  background: rgba(227, 231, 248, 0.25);
}
.card-medium.long {
  width: 90%;
}

.card-small {
  width: 4.5rem;
  height: 4.5rem;
  flex-shrink: 0;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: none;
}
.card-small .titlespan {
  margin-left: 0.25rem;
  margin-bottom: 1rem;
  font-size: 90%;
}
.card-small mat-icon {
  height: 1rem;
  width: 1rem;
  position: relative;
  top: 0.25rem;
  left: -0.125rem;
}
.card-small.warn {
  background: rgba(248, 227, 227, 0.25);
}
.card-small.primary {
  background: rgba(227, 231, 248, 0.25);
}
.card-small.long {
  width: 90%;
}

.small-card-content {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
}
.small-card-content.warn {
  color: #e01e5a;
}
.small-card-content.primary {
  color: var(--fontColor);
}

.mat-button-toggles-primary {
  margin: 10px;
}
.mat-button-toggles-primary .mat-button-toggle-checked {
  background-color: #5d7af6;
  color: white;
}
.mat-button-toggles-primary .mat-button-toggle {
  margin: -1px;
  width: 40px;
  height: 35px;
}

.mat-button-toggles-secondary {
  margin: 10px;
  background-color: white;
  color: #5d7af6;
  font-size: 10px;
  width: 120px;
}
.mat-button-toggles-secondary .mat-button-toggle-checked {
  color: white;
  font-weight: bold;
  background-color: #5d7af6;
  width: auto;
}
.mat-button-toggles-secondary .mat-button-toggle {
  margin: -1px;
  width: 120px;
  height: 100%;
}