@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$strayos-primary-palette: (
    50 : #eceffe,
    100 : #ced7fc,
    200 : #aebdfb,
    300 : #8ea2f9,
    400 : #758ef7,
    500 : #5d7af6,
    600 : #5572f5,
    700 : #4b67f3,
    800 : #415df2,
    900 : #304aef,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ccd2ff,
    A700 : #b2bcff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$strayos-warn-palette: (
    50 : #fbe4eb,
    100 : #f6bcce,
    200 : #f08fad,
    300 : #e9628c,
    400 : #e54073,
    500 : #e01e5a,
    600 : #dc1a52,
    700 : #d81648,
    800 : #d3123f,
    900 : #cb0a2e,
    A100 : #fff6f7,
    A200 : #ffc3cb,
    A400 : #ff90a0,
    A700 : #ff768a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$strayos-accent-palette: (
    50 : #e4e4e4,
    100 : #bbbbbb,
    200 : #8e8e8e,
    300 : #606160,
    400 : #3e3f3e,
    500 : #1c1d1c,
    600 : #191a19,
    700 : #141514,
    800 : #111111,
    900 : #090a09,
    A100 : #e07171,
    A200 : #d74747,
    A400 : #eb0000,
    A700 : #d10000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$app-primary: mat.define-palette($strayos-primary-palette);
$app-accent: mat.define-palette($strayos-accent-palette);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette($strayos-warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
	color: (
		primary: $app-primary,
		accent: $app-accent,
		warn: $app-warn,
	)
));

$app-theme-dark: mat.define-dark-theme((
	color: (
		primary: $app-primary,
		accent: $app-accent,
		warn: $app-warn,
	)
    
));

@include mat.all-component-themes($app-theme); 

.theme-dark {
    @include mat.all-component-themes($app-theme-dark);
    --fontColor: #707fff;
    --charts-background: transparent;
    --secondary-text-color: #ffffff;
    --action-button: #000000;
    --view-button: #000000;
    --sidebar-background: #5d7af6;
    --charts-font: #ffffff;
    --highcharts-labels:#ffffff;
    --scroll-bar:#000000;
    --dialog-background: #424242;
    --mat-toolbar:#303030;
    --popup-color: #ffffff;
    --panel-background: rgba(12, 12, 12, 0.5);
    --panel-background-solid: rgba(12, 12, 12, 0.5);
    --popup-background: #000000 !important;
    --svg-filter: brightness(0) saturate(100%) invert(94%) sepia(75%) saturate(26%) hue-rotate(259deg) brightness(113%) contrast(94%);
    --density-background: #000000;
    .mat-mdc-menu-content{
        background: unset !important;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 1.166666em !important;
    --mdc-icon-button-icon-size: 1.166666em !important;
    background-color: unset !important;
    color: unset !important;
    border-color: unset !important;
    font-size: inherit;
    padding: var(--spacing-small);
    box-sizing: content-box;
    line-height: 1;
    }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

/* You can add global styles to this file, and also import other style files */

$background: map-get($app-theme,background);
$foreground: map-get($app-theme,foreground);

:root{
    font-size: calc((0.9vmax + 18px)/2);
	margin: 0;
	font-family: Lato, "Helvetica Neue", sans-serif;
	background-color: map-get($background,background);
	color: map-get($foreground,text);
	height: 100%;
	caret-color: currentColor;
    --fontColor: #707fff;
    --charts-font: #000000;
    --sidebar-background:#5d7af6;
    --charts-background: #ffffff;
    --highcharts-labels:#777;
    --scroll-bar:#e3e7f8;
    --mat-toolbar:transparent;
    --dialog-container-background: #424242;
	--spacing-small: calc((0.5em + 0.5rem)/2);
	--spacing-medium: calc((1em + 1rem)/2);
	--spacing-large: calc((2em + 2rem)/2);
    --action-button:rgba(227, 231, 248, 0.5);
	--corner-radius: calc((0.223em + 0.223rem)/2);
    --svg-filter:'';
    --popup-background: rgb(231, 231, 245) !important;
    --popup-color:#000000;
	--shadow-blur: calc((0.4em + 0.4rem)/2);
	--shadow-color: rgba(0,0,0,0.5);
    --view-button:rgba(227, 231, 248, 0.5);
    --page-background: #{map-get($background,background)};
	--panel-background: rgba(255, 255, 255, 0.085);
	--panel-background-solid: #{map-get($background,card)};
	--dialog-background: #{map-get($background,card)};
	--divider-color: #{map-get($foreground,divider)};
    --density-background: #ffffff;
	--primary-text-color: #{map-get($foreground, text)};
	--secondary-text-color: #{map-get($foreground, secondary-text)};
}

body{
	height: 100%;
	padding: 0;
	margin: 0;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: map-get($foreground,text);
	//-webkit-box-shadow: 0 0 0px 1000px #000 inset;
	transition: background-color 5000s ease-in-out 0s;
}
