
mat-spinner{
	&.inline{
		width: 1em !important;
		height: 1em !important;

		>svg{
			width: 1em !important;
			height: 1em !important;
		}
	}
}
