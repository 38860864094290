::-webkit-scrollbar-button:single-button:vertical:increment, ::-webkit-scrollbar-button:single-button:vertical:decrement {
  display: none;
}

::-webkit-scrollbar {
  width: 0.4rem !important;
}

::-webkit-scrollbar-track {
  background: #e3e7f8;
}

::-webkit-scrollbar-thumb {
  border-color: #e3e7f8;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.15rem;
  background: #5d7af6;
}

::-webkit-scrollbar-button:single-button {
  background-color: #e3e7f8;
  display: block;
  border-style: solid;
  border: radius 0.15rem;
}